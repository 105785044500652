export const state = () => ({
  projectPartner: null,
  loading: false,
});

export const mutations = {
  SET_PROJECT(state, payload) {
    state.projectPartner = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async getProjectPartnerDetail({ commit, state }, hashId) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.get('/api/v2/project_partner/detail', {
        params: {
          hash_id: hashId,
        },
      });

      let project = data.data;
      if (project) {
        project.call =
          'https://api.whatsapp.com/send?phone=' +
          encodeURI('628119954981') +
          `&text=${encodeURIComponent(
            `Halo, saya ingin informasi lebih lanjut mengenai properti ${project.developer_name}`,
          )}%0a${project.url}` +
          '.';
        project.type =
          project.property_partner_units && project.property_partner_units.length > 0
            ? project.property_partner_units[0].property_type
            : null;
        project.developer_name = project.developer_web_url
          ? '<a href="' +
            encodeURI(project.developer_web_url) +
            '" target="_blank">' +
            project.developer_name +
            '</a>'
          : project.developer_name;
      }

      commit('SET_PROJECT', project);
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  // eslint-disable-next-line no-unused-vars
  clearProjectPartnerDetail({ commit, state }, params) {
    commit('SET_PROJECT', null);
  },
};
