export const state = () => ({
  urlParam: '',
  query: '',
  loading: false,

  name: null,
  listingPartnerUrl: null,
  panoEmbedUrl: null,
  displayPhotos: [],
  profilePictureUrl: null,
  addressLine1: null,
  addressLine2: null,
  description: null,
  brochureUrl: null,
  sketch: [],
  loadingPartnerDetail: false,
  showSwitchListingType: false,
  listingTypePriority: 'R',
});

export const mutations = {
  RESTORE_INITIAL_STATE(state) {
    state.urlParam = '';
    state.query = '';
    state.loading = false;
    state.listingPartnerUrl = null;
    state.panoEmbedUrl = null;
    state.displayPhotos = [];
    state.profilePictureUrl = null;
    state.addressLine1 = null;
    state.addressLine2 = null;
    state.loadingPartnerDetail = false;
    state.showSwitchListingType = false;
    state.listingTypePriority = 'R';
    state.brochureUrl = null;
    state.sketch = [];
  },

  SET_LOADING_PARTNER_DETAIL(state, payload) {
    state.loadingPartnerDetail = payload;
  },
  SET_NAME(state, payload) {
    state.name = payload;
  },
  SET_LISTING_TYPE_PRIORITY(state, payload) {
    state.listingTypePriority = payload;
  },
  SET_SHOW_SWITCH_LISTING_TYPE(state, payload) {
    state.showSwitchListingType = payload;
  },
  SET_URL_PARAM(state, payload) {
    state.urlParam = payload;
  },
  SET_DISPLAY_PHOTOS(state, payload) {
    state.displayPhotos = payload;
  },
  SET_PROFILE_PICTURE_URL(state, payload) {
    state.profilePictureUrl = payload;
  },
  SET_LISTING_PARTNER_URL(state, payload) {
    state.listingPartnerUrl = payload;
  },
  SET_ADDRESS_LINE_1(state, payload) {
    state.addressLine1 = payload;
  },
  SET_ADDRESS_LINE_2(state, payload) {
    state.addressLine2 = payload;
  },
  SET_PANO_EMBED_URL(state, payload) {
    state.panoEmbedUrl = payload;
  },
  SET_QUERY(state, payload) {
    state.query = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_DESCRIPTION(state, payload) {
    state.description = payload;
  },
  SET_BROCHURE_URL(state, payload) {
    state.brochureUrl = payload;
  },
  SET_SKETCH(state, payload) {
    state.sketch = payload;
  },
};

export const actions = {
  async getListingPartnerDetail({ commit, state }) {
    try {
      commit('SET_LOADING_PARTNER_DETAIL', true);
      const { data } = await this.$http.get('/api/v2/listing_partner/get_data', {
        params: {
          url: state.listingPartnerUrl,
        },
      });
      commit('SET_NAME', data.data.name);
      commit('SET_PROFILE_PICTURE_URL', data.data.profile_picture_url);
      commit('SET_PANO_EMBED_URL', data.data.pano_embed_url);
      commit('SET_ADDRESS_LINE_1', data.data.address_line_1);
      commit('SET_ADDRESS_LINE_2', data.data.address_line_2);
      commit('SET_DISPLAY_PHOTOS', data.data.display_photos);
      commit('SET_SHOW_SWITCH_LISTING_TYPE', data.data.show_switch_listing_type);
      commit('SET_LISTING_TYPE_PRIORITY', data.data.listing_type_priority);
      commit('SET_DESCRIPTION', data.data.description);
      commit('SET_BROCHURE_URL', data.data.brochure_url);
      commit('SET_SKETCH', data.data.sketch);
    } finally {
      commit('SET_LOADING_PARTNER_DETAIL', false);
    }
  },

  async getThreadMessages({ commit, state }) {
    try {
      commit('SET_FETCHING_MESSAGES', true);
      commit('SET_APPLICATION_DATA', null);
      commit('SET_MESSAGE_LIST', []);
      commit('SET_LISTING_DATA', null);
      commit('SET_USER_A', null);
      commit('SET_USER_B', null);

      const { data } = await this.$http.get('/api/v2/myinbox/get_thread_messages', {
        params: {
          messageThreadUuid: state.messageThreadUuid,
        },
      });
      commit('SET_FETCHING_MESSAGES', false);
      commit('SET_APPLICATION_DATA', data.data.applicationData);
      commit('SET_MESSAGE_LIST', data.data.messages);
      commit('SET_LISTING_DATA', data.data.listing);
      commit('SET_USER_A', data.data.userA);
      commit('SET_USER_B', data.data.userB);
    } finally {
      commit('SET_FETCHING_MESSAGES', false);
    }
  },
};
