export const state = () => ({
  listingUuid: '',
  listingData: null,
  listing: null,
  name: '',
  phoneNumber: '',
  email: '',
  numberOfInstallment: 6,
  oneYearInstallment: 0,
  sixMonthsInstallment: 0,
  twoYearInstallment: 0,
  cicilanData: [],
  selectedInstallment: null,
  rentPeriod: null,
  installmentDuration: null,
  date: null,
  loading: false,
  cicilSewa: null,
  gradana: null,
  company: null,
  occupation: null,
});

export const getters = {};

export const mutations = {
  SET_DATE(state, payload) {
    state.date = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_LISTING_UUID(state, payload) {
    state.listingUuid = payload;
  },
  SET_LISTING(state, payload) {
    state.listing = payload;
  },
  SET_LISTING_DATA(state, payload) {
    state.listingData = payload;
  },
  SET_NAME(state, payload) {
    state.name = payload;
  },
  SET_PHONE_NUMBER(state, payload) {
    state.phoneNumber = payload;
  },
  SET_EMAIL(state, payload) {
    state.email = payload;
  },
  SET_NUMBER_OF_INSTALLMENT(state, payload) {
    state.numberOfInstallment = payload;
  },
  SET_ONE_YEAR_INSTALLMENT(state, payload) {
    state.oneYearInstallment = payload;
  },
  SET_TWO_YEAR_INSTALLMENT(state, payload) {
    state.twoYearInstallment = payload;
  },
  SET_SIX_MONTHS_INSTALLMENT(state, payload) {
    state.sixMonthsInstallment = payload;
  },
  SET_SELECTED_INSTALLMENT(state, payload) {
    state.selectedInstallment = payload;
  },
  SET_RENT_PERIOD(state, payload) {
    state.rentPeriod = payload;
  },
  SET_INSTALLMENT_DURATION(state, payload) {
    state.installmentDuration = payload;
  },
  SET_CICILAN_DATA(state, payload) {
    state.cicilanData = payload;
  },
  SET_CICIL_SEWA(state, payload) {
    state.cicilSewa = payload;
  },
  SET_GRADANA(state, payload) {
    state.gradana = payload;
  },
  SET_COMPANY(state, payload) {
    state.company = payload;
  },
  SET_OCCUPATION(state, payload) {
    state.occupation = payload;
  },
  RESTORE_INITIAL_STATE(state) {
    state.listingData = null;
    state.listing = null;
    state.name = '';
    state.phoneNumber = '';
    state.email = '';
    state.numberOfInstallment = 6;
    state.oneYearInstallment = 0;
    state.sixMonthsInstallment = 0;
    state.twoYearInstallment = 0;
    state.cicilanData = [];
    state.cicilSewa = null;
    state.gradana = null;
    state.company = null;
    state.occupation = null;
    state.selectedInstallment = null;
    state.rentPeriod = null;
    state.installmentDuration = null;
  },
};

export const actions = {
  async getData({ commit, state }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.get('/api/v2/fintech_installment/get_data', {
        params: {
          listingUuid: state.listingUuid,
          company: state.company,
        },
      });
      commit('SET_LISTING_DATA', data.data.listingData);
      commit('SET_LISTING', data.data.listing);
      commit('SET_NAME', data.data.applicantData.name);
      commit('SET_PHONE_NUMBER', data.data.applicantData.phoneNumber);
      commit('SET_EMAIL', data.data.applicantData.email);
      commit('SET_SIX_MONTHS_INSTALLMENT', data.data.cicilanData.sixMonthsInstallment);
      commit('SET_ONE_YEAR_INSTALLMENT', data.data.cicilanData.oneYearInstallment);
      commit('SET_TWO_YEAR_INSTALLMENT', data.data.cicilanData.twoYearInstallment);
      commit('SET_CICILAN_DATA', data.data.cicilanData);
      commit('SET_SELECTED_INSTALLMENT', data.data.cicilanData ? data.data.cicilanData[0] : null);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getComparison({ commit }, { listingUuid }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.get('/api/v2/fintech_installment/get_comparison', {
        params: {
          listing_uuid: listingUuid,
        },
      });
      commit('SET_CICIL_SEWA', data.data.cicilsewa);
      commit('SET_GRADANA', data.data.gradana);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async postRequest({ commit, state, rootState }) {
    try {
      commit('SET_LOADING', true);
      let postData = {
        name: state.name,
        phoneNumber: state.phoneNumber,
        email: state.email,
        occupation: state.occupation,
        listingUuid: state.listingUuid,
        date: this.$date.format(state.date, 'dd-MM-yyyy'),
        rent_period_in_year: state.rentPeriod,
        // company: state.company,
      };
      if (state.company === 'cicilsewa') {
        postData.rent_period_in_year = state.selectedInstallment
          ? state.selectedInstallment.rent_period_in_year
          : '';
        postData.occupation = state.occupation;
      } else if (state.company === 'gradana') {
        postData.numberOfInstallment = state.numberOfInstallment;
      }
      if (!rootState.global.loggedIn) {
        postData.registerData = {
          name: rootState.signUp.name,
          email: rootState.signUp.email,
          password: rootState.signUp.password,
          phone: rootState.signUp.phone,
        };
      }
      // eslint-disable-next-line no-unused-vars
      const { data } = await this.$http.post('/api/v2/fintech_installment/initiate', postData);
      // if(data){
      //     await dispatch('updateToken', data.token,{root:true});
      //     await dispatch('fetch',{},{root:true});
      //     return true
      // }
      return true;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
