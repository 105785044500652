export const state = () => ({
  fullName: null,
  ktp: null,
  phone: null,
  address: null,
  city: null,
  npwp: null,
  npwpPhotoUuid: null,
  ktpPhotoUuid: null,
  initRegionalData: null,
});

export const getters = {
  formData(state) {
    return {
      full_name: state.fullName,
      ktp: state.ktp,
      phone: state.phone,
      address: state.address,
      city: state.city,
      npwp: state.npwp,
      npwp_photo_uuid: state.npwpPhotoUuid,
      ktp_photo_uuid: state.ktpPhotoUuid,
    };
  },
};
export const mutations = {
  SET_FULL_NAME(state, payload) {
    state.fullName = payload;
  },
  SET_INIT_REGIONAL_DATA(state, payload) {
    state.initRegionalData = payload;
  },
  SET_PHONE(state, payload) {
    state.phone = payload;
  },
  SET_ADDRESS(state, payload) {
    state.address = payload;
  },
  SET_CITY(state, payload) {
    state.city = payload;
  },
  SET_NPWP(state, payload) {
    state.npwp = payload;
  },
  SET_NPWP_PHOTO_UUID(state, payload) {
    state.npwpPhotoUuid = payload;
  },
  SET_KTP(state, payload) {
    state.ktp = payload;
  },
  SET_KTP_PHOTO_UUID(state, payload) {
    state.ktpPhotoUuid = payload;
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_FULL_NAME', null);
    commit('SET_PHONE', null);
    commit('SET_ADDRESS', null);
    commit('SET_NPWP', null);
    commit('SET_NPWP_PHOTO_UUID', null);
    commit('SET_KTP', null);
    commit('SET_KTP_PHOTO_UUID', null);
    commit('SET_INIT_REGIONAL_DATA', null);
    commit('SET_CITY', null);
  },

  applyGetData({ commit }, data) {
    commit('SET_FULL_NAME', data.full_name);
    commit('SET_PHONE', data.phone);
    commit('SET_ADDRESS', data.address);
    commit('SET_NPWP', data.npwp);
    commit('SET_KTP', data.identity_card_number);
    commit('SET_CITY', data.city);
    commit('SET_INIT_REGIONAL_DATA', data.regional_data);
  },
};
