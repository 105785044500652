const TimeOfDay = require('@/models/TimeOfDay.js');
const HelperUtils = require('@/utils/helperUtils.js');

export const state = () => ({
  allowOperationalHour: true,
  differentTime: false,
  showOperationalHour: false,
  listingOperationals: [
    {
      day: '1',
      show: false,
      showTime: true,
      selectedOpenTime: new TimeOfDay(9, 0),
      selectedCloseTime: new TimeOfDay(17, 0),
      closeOptions: [],
      openOptions: [],
    },
    {
      day: '2',
      show: false,
      showTime: true,
      selectedOpenTime: new TimeOfDay(9, 0),
      selectedCloseTime: new TimeOfDay(17, 0),
      closeOptions: [],
      openOptions: [],
    },
    {
      day: '3',
      show: false,
      showTime: true,
      selectedOpenTime: new TimeOfDay(9, 0),
      selectedCloseTime: new TimeOfDay(17, 0),
      closeOptions: [],
      openOptions: [],
    },
    {
      day: '4',
      show: false,
      showTime: true,
      selectedOpenTime: new TimeOfDay(9, 0),
      selectedCloseTime: new TimeOfDay(17, 0),
      closeOptions: [],
      openOptions: [],
    },
    {
      day: '5',
      show: false,
      showTime: true,
      selectedOpenTime: new TimeOfDay(9, 0),
      selectedCloseTime: new TimeOfDay(17, 0),
      closeOptions: [],
      openOptions: [],
    },
    {
      day: '1-5',
      show: true,
      showTime: true,
      selectedOpenTime: new TimeOfDay(9, 0),
      selectedCloseTime: new TimeOfDay(17, 0),
      closeOptions: [],
      openOptions: [],
    },
    {
      day: '6',
      show: true,
      showTime: true,
      selectedOpenTime: new TimeOfDay(9, 0),
      selectedCloseTime: new TimeOfDay(17, 0),
      closeOptions: [],
      openOptions: [],
    },
    {
      day: '7',
      show: true,
      showTime: true,
      selectedOpenTime: new TimeOfDay(9, 0),
      selectedCloseTime: new TimeOfDay(17, 0),
      closeOptions: [],
      openOptions: [],
    },
  ],
});

export const getters = {
  getListingOperational: (state) => (day) => {
    const lo = state.listingOperationals.find(function (lo_) {
      return lo_.day == day;
    });
    return lo;
  },
  getSelectedOpenTime: (state) => (day) => {
    const lo = state.listingOperationals.find(function (lo_) {
      return lo_.day == day;
    });
    if (lo.selectedOpenTime !== null) {
      return {
        time: lo.selectedOpenTime,
        $isDisabled: false,
        timeLabel: HelperUtils.timeOfDayToString(lo.selectedOpenTime),
      };
    }
    return null;
  },

  getSelectedCloseTime: (state) => (day) => {
    const lo = state.listingOperationals.find(function (lo_) {
      return lo_.day == day;
    });

    if (lo.selectedCloseTime) {
      return {
        time: lo.selectedCloseTime,
        $isDisabled: false,
        timeLabel: HelperUtils.timeOfDayToString(lo.selectedCloseTime),
      };
    }
    return null;
  },

  getOpenOptions: (state) => (day) => {
    const lo = state.listingOperationals.find(function (lo_) {
      return lo_.day == day;
    });
    return lo.openOptions.map((value) => {
      const result = HelperUtils.timeOfDayToString(value.time);
      return {
        time: value.time,
        timeLabel: result,
        $isDisabled: value.$isDisabled,
      };
    });
  },
  getCloseOptions: (state) => (day) => {
    const lo = state.listingOperationals.find(function (lo_) {
      return lo_.day == day;
    });

    return lo.closeOptions.map((value) => {
      const result = HelperUtils.timeOfDayToString(value.time);
      return {
        time: value.time,
        timeLabel: result,
        $isDisabled: value.$isDisabled,
      };
    });
    // return lo.closeOptions.map(value => String(value.time.toString()));
  },
  selectedListingOperationals(state, getters) {
    const listingOperationals = JSON.parse(JSON.stringify(state.listingOperationals));
    const checkedListingOperational = listingOperationals.filter(function (lo) {
      return lo.showTime;
    });
    const allowedDiffTimeShow = ['1-5', '6', '7'];
    let filteredLO;
    if (state.differentTime) {
      filteredLO = checkedListingOperational.filter(function (lo) {
        return String(lo.day) !== '1-5';
      });
    } else {
      filteredLO = checkedListingOperational.filter(function (lo) {
        return allowedDiffTimeShow.indexOf(String(lo.day)) !== -1;
      });
    }
    let operationalTime = {};

    const days = filteredLO.map(function (lo) {
      let openTime = HelperUtils.timeOfDayToString(getters.getSelectedOpenTime(lo.day).time);
      let closeTime = HelperUtils.timeOfDayToString(getters.getSelectedCloseTime(lo.day).time);
      operationalTime['operationalOpenTime_' + lo.day] = openTime;
      operationalTime['operationalCloseTime_' + lo.day] = closeTime;
      return lo.day;
    });
    return Object.assign(
      {},
      { differentTimeCheck: state.differentTime },
      { operationalDay: days },
      operationalTime,
    );
  },
  formData(state, getters) {
    return {
      ...getters.selectedListingOperationals,
      ...{
        withOperationalHour: state.showOperationalHour,
      },
    };
  },
};

export const mutations = {
  SET_ALLOW_OPERATIONAL_HOUR(state, payload) {
    state.allowOperationalHour = payload;
  },
  SET_SHOW_OPERATIONAL_HOUR(state, payload) {
    state.showOperationalHour = payload;
  },
  TOGGLE_DIFFERENT_TIME(state, payload) {
    state.differentTime = payload;
    const allowedDiffTimeShow = ['1-5', '6', '7'];
    if (payload) {
      // jika differentTime = true
      state.listingOperationals.forEach(function (currentLO) {
        if (currentLO.day === '1-5') {
          currentLO.show = false;
        } else {
          currentLO.show = true;
        }
      });
    } else {
      state.listingOperationals.forEach(function (currentLO) {
        if (allowedDiffTimeShow.indexOf(String(currentLO.day)) !== -1) {
          currentLO.show = true;
        } else {
          currentLO.show = false;
        }
      });
    }
  },
  OPEN_LISTING_OPERATIONAL(state, day) {
    let lo = state.listingOperationals.find(function (lo_) {
      return lo_.day == day;
    });
    lo.showTime = true;
  },
  CLOSE_LISTING_OPERATIONAL(state, day) {
    let lo = state.listingOperationals.find(function (lo_) {
      return lo_.day == day;
    });
    lo.showTime = false;
  },
  TOGGLE_LISTINGOPERATIONAL_OPEN(state, day) {
    let lo = state.listingOperationals.find(function (lo_) {
      return lo_.day == day;
    });
    lo.showTime = !lo.showTime;
  },
  SET_OPEN_TIME(state, payload) {
    const lo = state.listingOperationals.find(function (lo_) {
      return lo_.day == payload.day;
    });

    lo.selectedOpenTime = payload.newSelected;
  },
  SET_CLOSE_TIME(state, payload) {
    const lo = state.listingOperationals.find(function (lo_) {
      return lo_.day == payload.day;
    });
    lo.selectedCloseTime = payload.newSelected;
  },

  SET_DEFAULT_OPEN_TIME_OPTIONS(state) {
    let openOptions = [];
    let closeOptions = [];
    for (let i = 0; i <= 22; i++) {
      for (let j = 0; j <= 30; j = j + 30) {
        openOptions.push({
          time: new TimeOfDay(i, j),
          $isDisabled: i >= 17,
        });
      }
    }
    for (let i = 1; i <= 23; i++) {
      for (let j = 0; j <= 30; j = j + 30) {
        closeOptions.push({
          time: new TimeOfDay(i, j),
          $isDisabled: i <= 9,
        });
      }
    }

    state.listingOperationals.map(function (element) {
      element.openOptions = openOptions;
      element.closeOptions = closeOptions;
      return element;
    });
  },

  CONFIGURE_DISABLED_OPEN_TIME(state, { day, selectedOpenTime }) {
    const lo = state.listingOperationals.find(function (lo_) {
      return lo_.day == day;
    });

    let newOptions = lo.openOptions.map(function (currItem) {
      let openOption = JSON.parse(JSON.stringify(currItem));
      openOption.time = new TimeOfDay(openOption.time.hours, openOption.time.minutes);
      const formattedCloseTime = new TimeOfDay(selectedOpenTime.hours, selectedOpenTime.minutes);
      openOption.$isDisabled = openOption.time >= formattedCloseTime;
      return openOption;
    });

    lo.openOptions = newOptions;
  },

  CONFIGURE_DISABLED_CLOSE_TIME(state, { day, selectedCloseTime }) {
    const lo = state.listingOperationals.find(function (lo_) {
      return lo_.day == day;
    });
    let newOptions = lo.closeOptions.map(function (currItem) {
      let closeOption = JSON.parse(JSON.stringify(currItem));
      closeOption.time = new TimeOfDay(closeOption.time.hours, closeOption.time.minutes);
      const formattedCloseTime = new TimeOfDay(selectedCloseTime.hours, selectedCloseTime.minutes);
      closeOption.$isDisabled = closeOption.time <= formattedCloseTime;
      return closeOption;
    });
    lo.closeOptions = newOptions;
  },
  RESTORE_INITIAL_STATE(state) {
    state.allowOperationalHour = true;
    state.differentTime = false;
    state.listingOperationals = [
      {
        day: '1',
        show: false,
        showTime: true,
        selectedOpenTime: new TimeOfDay(9, 0),
        selectedCloseTime: new TimeOfDay(17, 0),
        closeOptions: [],
        openOptions: [],
      },
      {
        day: '2',
        show: false,
        showTime: true,
        selectedOpenTime: new TimeOfDay(9, 0),
        selectedCloseTime: new TimeOfDay(17, 0),
        closeOptions: [],
        openOptions: [],
      },
      {
        day: '3',
        show: false,
        showTime: true,
        selectedOpenTime: new TimeOfDay(9, 0),
        selectedCloseTime: new TimeOfDay(17, 0),
        closeOptions: [],
        openOptions: [],
      },
      {
        day: '4',
        show: false,
        showTime: true,
        selectedOpenTime: new TimeOfDay(9, 0),
        selectedCloseTime: new TimeOfDay(17, 0),
        closeOptions: [],
        openOptions: [],
      },
      {
        day: '5',
        show: false,
        showTime: true,
        selectedOpenTime: new TimeOfDay(9, 0),
        selectedCloseTime: new TimeOfDay(17, 0),
        closeOptions: [],
        openOptions: [],
      },
      {
        day: '1-5',
        show: true,
        showTime: true,
        selectedOpenTime: new TimeOfDay(9, 0),
        selectedCloseTime: new TimeOfDay(17, 0),
        closeOptions: [],
        openOptions: [],
      },
      {
        day: '6',
        show: true,
        showTime: true,
        selectedOpenTime: new TimeOfDay(9, 0),
        selectedCloseTime: new TimeOfDay(17, 0),
        closeOptions: [],
        openOptions: [],
      },
      {
        day: '7',
        show: true,
        showTime: true,
        selectedOpenTime: new TimeOfDay(9, 0),
        selectedCloseTime: new TimeOfDay(17, 0),
        closeOptions: [],
        openOptions: [],
      },
    ];
  },
};

export const actions = {
  applyEditData({ commit, dispatch }, { data }) {
    const listingOperationalData = data.listing_operational_data;
    if (listingOperationalData == null) {
      commit('SET_SHOW_OPERATIONAL_HOUR', false);
      return;
    }
    const isDifferent = listingOperationalData.isDifferent;
    const newListingOperationals = listingOperationalData.listingOperationals;
    if (newListingOperationals) {
      commit('SET_SHOW_OPERATIONAL_HOUR', true);
      if (isDifferent) {
        let fullWeek = [1, 2, 3, 4, 5, 6, 7];
        commit('TOGGLE_DIFFERENT_TIME', true); // dijadiin isDifferent = true
        newListingOperationals.forEach(function (lo) {
          commit('OPEN_LISTING_OPERATIONAL', lo.day_of_the_week);
          dispatch('setSelectedOpenTime', {
            day: lo.day_of_the_week,
            newSelected: new TimeOfDay.parse(lo.start_time),
          });
          dispatch('setSelectedCloseTime', {
            day: lo.day_of_the_week,
            newSelected: new TimeOfDay.parse(lo.end_time),
          });
          // remove dari arr full week yang udah masuk loop
          fullWeek = fullWeek.filter((e) => e !== parseInt(lo.day_of_the_week));
        });
        fullWeek.forEach(function (day) {
          commit('CLOSE_LISTING_OPERATIONAL', day);
        });
      } else {
        const lo1 = newListingOperationals.find(function (photo) {
          return photo.day_of_the_week == 1;
        });
        if (lo1) {
          for (let i = 1; i < 6; i++) {
            commit('OPEN_LISTING_OPERATIONAL', lo1.day_of_the_week);
            dispatch('setSelectedOpenTime', {
              day: '1-5',
              newSelected: new TimeOfDay.parse(lo1.start_time),
            });
            dispatch('setSelectedCloseTime', {
              day: '1-5',
              newSelected: new TimeOfDay.parse(lo1.end_time),
            });
          }
          commit('OPEN_LISTING_OPERATIONAL', '1-5');
          dispatch('setSelectedOpenTime', {
            day: '1-5',
            newSelected: new TimeOfDay.parse(lo1.start_time),
          });
          dispatch('setSelectedCloseTime', {
            day: '1-5',
            newSelected: new TimeOfDay.parse(lo1.end_time),
          });
        } else {
          commit('CLOSE_LISTING_OPERATIONAL', '1-5');
        }
        const lo6 = newListingOperationals.find(function (photo) {
          return photo.day_of_the_week == 6;
        });
        if (lo6) {
          commit('OPEN_LISTING_OPERATIONAL', lo6.day_of_the_week);
          dispatch('setSelectedOpenTime', {
            day: lo6.day_of_the_week,
            newSelected: new TimeOfDay.parse(lo6.start_time),
          });
          dispatch('setSelectedCloseTime', {
            day: lo6.day_of_the_week,
            newSelected: new TimeOfDay.parse(lo6.end_time),
          });
        } else {
          commit('CLOSE_LISTING_OPERATIONAL', 6);
        }
        const lo7 = newListingOperationals.find(function (photo) {
          return photo.day_of_the_week == 7;
        });
        if (lo7) {
          commit('OPEN_LISTING_OPERATIONAL', lo7.day_of_the_week);
          dispatch('setSelectedOpenTime', {
            day: lo7.day_of_the_week,
            newSelected: new TimeOfDay.parse(lo7.start_time),
          });
          dispatch('setSelectedCloseTime', {
            day: lo7.day_of_the_week,
            newSelected: new TimeOfDay.parse(lo7.end_time),
          });
        } else {
          commit('CLOSE_LISTING_OPERATIONAL', 7);
        }
      }
    } else {
      commit('SET_SHOW_OPERATIONAL_HOUR', false);
    }
  },
  setSelectedOpenTime({ commit }, payload) {
    commit('SET_OPEN_TIME', {
      day: payload.day,
      newSelected: payload.newSelected,
    });

    commit('CONFIGURE_DISABLED_CLOSE_TIME', {
      day: payload.day,
      selectedCloseTime: payload.newSelected,
    });
  },
  setSelectedCloseTime({ commit }, payload) {
    commit('SET_CLOSE_TIME', {
      day: payload.day,
      newSelected: payload.newSelected,
    });

    commit('CONFIGURE_DISABLED_OPEN_TIME', {
      day: payload.day,
      selectedOpenTime: payload.newSelected,
    });
  },
};
