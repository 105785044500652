export const state = () => ({
  promoList: [],
  promoDetail: null,
  isLoading: true,
  copiedPromoCode: null,
});

export const mutations = {
  SET_COPIED_PROMO_CODE(state, payload) {
    state.copiedPromoCode = payload;
  },
  SET_PROMO_LIST(state, payload) {
    state.promoList = payload;
  },
  SET_PROMO_DETAIL(state, payload) {
    state.promoDetail = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_PROMO_LIST', []);
    commit('SET_PROMO_DETAIL', null);
    commit('SET_COPIED_PROMO_CODE', null);
  },
  async getPromoList({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.get('/api/v2/promo/get_promo_list');
      commit('SET_PROMO_LIST', data.data);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
