export const state = () => ({
  mapHeight: null,
  mapWidth: null,
  bodyHeight: null,
  bodyWidth: 1000,
  filterHeight: 130,
  headerHeight: 50,
  contentMaps: false, // Nilai default untuk apakah map terbuka (true) atau tertutup (false) ketika pertama loading halaman search.
});

export const getters = {
  filterSize(state) {
    return state.bodyWidth - state.mapWidth;
  },
};

export const mutations = {
  SET_MAP_HEIGHT(state, payload) {
    state.mapHeight = payload;
  },
  SET_MAP_WIDTH(state, payload) {
    state.mapWidth = payload;
  },
  SET_HEIGHT(state, payload) {
    state.bodyHeight = payload;
  },
  SET_WIDTH(state, payload) {
    state.bodyWidth = payload;
  },
  SET_FILTER_HEIGHT(state, payload) {
    state.filterHeight = payload;
  },
  SET_HEADER_HEIGHT(state, payload) {
    console.log('SET_HEADER_HEIGHT: ', payload);
    state.headerHeight = payload;
  },
  SET_CLOSE_MAP(state, payload) {
    state.contentMaps = payload;
  },
};
