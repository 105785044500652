export const state = () => ({
  listings: [],
  projects: [],
  premierListings: [],
  geoJson: [],
  meta: {},
  metaProject: {},
  hoverActive: false,
  shortlists: [],
  isShowMenuMobile: false,
  showMobileFilter: false,
  showMobileSort: false,
  propertyCategoryList: [
    {
      id: 6,
      urlName: 'stay',
    },
    {
      id: 1,
      urlName: 'business',
    },
    {
      id: 2,
      urlName: 'inventory',
    },
  ],
  city: {},
  kotaOptions: [
    {
      id: 1,
      name: 'Jakarta',
      search: 'search/stay?query=jakarta&category',
    },
    {
      id: 2,
      name: 'Bogor',
      search: 'search/stay?query=3201&category=city',
    },
    {
      id: 3,
      name: 'Depok',
      search: 'search/stay?query=3276&category=city',
    },
    {
      id: 4,
      name: 'Tangerang',
      search: 'search/stay?query=3671&category=city',
    },
    {
      id: 5,
      name: 'Bekasi',
      search: 'search/stay?query=3275&category=city',
    },
  ],
  selectedCategoryId: null,
  selectedProjectStatusId: null,
  openCategories: true,
  coverSearch: true,
  focusInputSearch: false,
  rangePrices: [
    { id: 1, name: '0 - 250.000' },
    { id: 2, name: '250.000 - 1.000.000' },
    { id: 3, name: '> 1.000.000' },
    { id: 4, name: '0 - 1.000.000' },
    { id: 5, name: '1.000.000 - 5.000.000' },
    { id: 6, name: '> 5.000.000' },
    { id: 7, name: '0 - 10.000.000' },
    { id: 8, name: '10.000.000 - 50.000.000' },
    { id: 9, name: '> 50.000.000' },
    { id: 10, name: '0 - 250.000' },
    { id: 11, name: '50.000 - 250.000' },
    { id: 12, name: '> 250.000' },
    { id: 13, name: '0 - 250.000' },
    { id: 14, name: '250.000 - 1.000.000' },
    { id: 15, name: '> 1.000.000' },
  ],
  sortByOptions: [
    { id: 1, name: 'relevance', searchType: null },
    { id: 2, name: 'size_asc', searchType: 2 },
    { id: 3, name: 'size_dsc', searchType: 2 },
    { id: 4, name: 'price_asc', searchType: 2 },
    { id: 5, name: 'price_dsc', searchType: 2 },
    { id: 6, name: 'last_update', searchType: null },
    { id: 7, name: 'old_update', searchType: null },
  ],
  categories: [
    { id: null, name: 'all', text: null },
    { id: 7, name: 'residential' },
    { id: 8, name: 'commercial' },
  ],
  projectStatuses: [
    { id: 1, name: 'all' },
    { id: 2, name: 'done' },
    { id: 3, name: 'in_progress' },
  ],
  currentKeyword: null,
  searchListingOptions: [],
  searchTerm: null,
  searchTermId: null,
  searchTermCategory: null,
  page: 1,
  rentPeriod: {
    id: null,
    name: null,
  },
  rentPrice: {
    id: null,
    name: null,
  },
  propertyTypeId: null,
  rentTypeId: [],
  rangePrice: 0,
  rangePriceName: '',
  buildingSizeMin: null,
  buildingSizeMax: null,
  rentPriceMin: null,
  rentPriceMax: null,
  sellPriceMin: null,
  sellPriceMax: null,
  sortBy: { id: 1, name: 'relevance' },
  category: { id: null, name: 'all' },
  projectStatus: { id: 1, name: 'all' },
  listingType: 'R',
  panoRequired: false,
  isLoading: false,
  seed: null,
  searchType: 2, // 1 = project partner, 2 = semua listing
  searchTypes: [
    {
      id: 2,
      name: 'general.all',
    },
    {
      id: 1,
      name: 'general.filter.projectPartner',
    },
  ],
  categoryNavList: [
    { name: 'active', isActive: true, count: 0 },
    { name: 'draft', isActive: false, count: 0 },
    { name: 'notActive', isActive: false, count: 0 },
    { name: 'sold', isActive: false, count: 0 },
  ],
  activeFilterSidebar: false,
  isOfficialPartner: false,
  officialPartnerAreasData: [],
  officialPartnerAreasMeta: null,
  activePropertyTypes: [],
  selectedCities: [],
});

export const getters = {
  activeCategory(state) {
    return state.categoryNavList.find((category) => {
      return category.isActive;
    }).name;
  },
  activePropertyType(state, getters, rootState) {
    return rootState.v2.masters.propertyTypes.find(function (propertyType) {
      return propertyType.id === state.propertyTypeId;
    });
  },
  activeSortBy(state) {
    return state.sortByOptions.find(function (sortBy) {
      return sortBy.id === state.sortBy.id;
    });
  },
  activeRangePrice(state) {
    return state.rangePrices.find(function (rangePrice) {
      return rangePrice.id === state.rangePrice;
    });
  },
  rangePricesSearch(state) {
    let rangePrices = state.rangePrices;
    rangePrices.unshift({
      id: 0,
      name: 'general.all',
    });
    return rangePrices;
  },
};

export const mutations = {
  SET_SHOW_MOBILE_FILTER(state, payload) {
    state.showMobileFilter = payload;
  },
  SET_SHOW_MOBILE_SORT(state, payload) {
    state.showMobileSort = payload;
  },
  SET_SELECTED_CATEGORY_ID(state, payload) {
    state.selectedCategoryId = payload;
  },
  SET_SELECTED_PROJECT_STATUS_ID(state, payload) {
    state.selectedProjectStatusId = payload;
  },
  set_listings(state, payload) {
    state.listings = payload.data;
    state.meta = payload.meta;
  },
  set_projects(state, payload) {
    state.projects = payload.data;
    state.metaProject = payload.meta;
  },
  set_geo_json(state, payload) {
    state.geoJson = payload;
  },
  set_hover_map(state, payload) {
    state.hoverActive = payload;
  },
  set_shortlists(state, payload) {
    state.shortlists = payload;
  },
  SET_SHOW_MENU_MOBILE(state, payload) {
    state.isShowMenuMobile = payload;
  },
  set_city(state, payload) {
    state.city = payload;
  },
  set_premier_listings(state, payload) {
    state.premierListings = payload.data;
  },
  SET_OPEN_CATEGORIES(state, payload) {
    state.openCategories = payload;
  },
  SET_COVER_SEARCH(state, payload) {
    state.coverSearch = payload;
  },
  SET_FOCUS_INPUT_SEARCH(state, payload) {
    state.focusInputSearch = payload;
  },

  SET_SEARCH_TERM_ID(state, payload) {
    state.searchTermId = payload;
  },
  SET_SEARCH_TERM_CATEGORY(state, payload) {
    state.searchTermCategory = payload;
  },
  SET_SEARCH_TERM(state, payload) {
    state.searchTerm = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_RENT_PERIOD(state, payload) {
    state.rentPeriod = payload;
  },
  SET_RENT_PRICE(state, payload) {
    state.rentPrice = payload;
  },
  SET_PROPERTY_TYPE_ID(state, payload) {
    state.propertyTypeId = payload;
  },
  SET_RENT_TYPE_ID(state, payload) {
    state.rentTypeId = payload;
  },
  SET_RANGE_PRICE(state, payload) {
    state.rangePrice = payload;

    let rangePriceSelected = state.rangePrices.find(function (item) {
      return item.id === payload;
    });

    state.rangePriceName = rangePriceSelected ? rangePriceSelected.name : '';
  },
  SET_RANGE_PRICE_NAME(state, payload) {
    state.rangePriceName = payload;
  },
  SET_BUILDING_SIZE_MIN(state, payload) {
    state.buildingSizeMin = payload;
  },
  SET_BUILDING_SIZE_MAX(state, payload) {
    state.buildingSizeMax = payload;
  },
  SET_RENT_PRICE_MIN(state, payload) {
    state.rentPriceMin = payload;
  },
  SET_RENT_PRICE_MAX(state, payload) {
    state.rentPriceMax = payload;
  },
  SET_SELL_PRICE_MIN(state, payload) {
    state.sellPriceMin = payload;
  },
  SET_SELL_PRICE_MAX(state, payload) {
    state.sellPriceMax = payload;
  },
  SET_SORT_BY(state, payload) {
    state.sortBy = payload;
  },
  SET_CATEGORY(state, payload) {
    state.category = payload;
  },
  SET_PROJECT_STATUS(state, payload) {
    state.projectStatus = payload;
  },
  SET_LISTING_TYPE(state, payload) {
    state.listingType = payload;
  },
  SET_PANO_REQUIRED(state, payload) {
    state.panoRequired = payload;
  },
  SET_SEED(state, payload) {
    state.seed = payload;
  },
  SET_SEARCH_TYPE(state, payload) {
    state.searchType = payload;
  },
  SET_CURRENT_KEYWORD(state, payload) {
    state.currentKeyword = payload;
  },
  SET_ACTIVE_FILTER_SIDEBAR(state, payload) {
    state.activeFilterSidebar = payload;
  },
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_IS_OFFICIAL_PARTNER(state, payload) {
    state.isOfficialPartner = payload;
  },
  SET_OFFICIAL_PARTNER_AREAS_DATA(state, payload) {
    state.officialPartnerAreasData = payload;
  },
  SET_OFFICIAL_PARTNER_AREAS_META(state, payload) {
    state.officialPartnerAreasMeta = payload;
  },
  SET_ACTIVE_PROPERTY_TYPES(state, payload) {
    state.activePropertyTypes = payload;
  },
  SET_SELECTED_CITIES(state, payload) {
    state.selectedCities = payload;
  },
  CLEAR_FILTER(state) {
    state.page = 1;
    state.selectedCategoryId = null;
    state.rentPeriod = {
      id: null,
      name: null,
    };
    state.rentPrice = {
      id: null,
      name: null,
    };
    state.propertyTypeId = null;
    state.rentTypeId = [];
    state.rangePrice = 0;
    state.rangePriceName = '';
    state.buildingSizeMin = null;
    state.buildingSizeMax = null;
    state.rentPriceMin = null;
    state.rentPriceMax = null;
    state.sellPriceMin = null;
    state.sellPriceMax = null;
    state.sortBy = { id: 1, name: 'relevance', searchType: null };
    state.category = { id: null, name: 'all' };
    state.panoRequired = false;
    state.activePropertyTypes = [];
    state.selectedCities = [];
  },
};

export const actions = {
  convertCategoryIdToUrlName(_, categoryId) {
    switch (categoryId) {
      case 6:
        return 'stay';
      case 1:
        return 'business';
      case 2:
        return 'inventory';
      default:
        return null;
    }
  },

  convertCategoryStringToId(_, stringData) {
    if (!stringData) return null;
    switch (stringData.toUpperCase()) {
      case 'STAY':
        return 6;
      case 'BUSINESS':
        return 1;
      case 'INVENTORY':
        return 2;
      default:
        return null;
    }
  },

  convertListingTypeStringToId(_, stringData) {
    if (!stringData) return null;
    switch (stringData.toUpperCase()) {
      case 'SEWA':
        return 'R';
      case 'JUAL':
        return 'S';
      case 'BELI':
        return 'S';
      default:
        return null;
    }
  },

  async getSuggestions(_, { query, listingType, searchType }) {
    let response = await this.$http.get('/api/v2/search/suggestions', {
      params: {
        query: query,
        listing_type: listingType,
        search_type: searchType,
      },
    });

    return response.data;
  },

  async getProjectPartners({ commit, state, dispatch }, { query, params }) {
    let self = this;

    console.log('v2/search/getProjectPartners Query: ', query);
    if (query) await dispatch('loadRouteQuery', { query: query, params: params });

    query = Object.assign({}, query, {
      page: state.page,
      listing_type: state.listingType,
      query: state.searchTerm,
      property_type_id: state.propertyTypeId,
    });

    if (!query.searchTerm && (!query.sort_by || query.sort_by === '1')) {
      if (!state.seed) {
        dispatch('generateSearchSeed');
      }
    } else {
      commit('SET_SEED', null);
    }

    let querySearch = JSON.parse(JSON.stringify(query));
    delete querySearch.query; // Search project partner tidak pakai teks search.

    await new Promise(async function (resolve, reject) {
      try {
        querySearch = Object.assign({}, querySearch, {
          paginate: 30,
          seed: state.seed,
        });
        let response = await self.$http.get('/api/v2/search/project-partners', {
          params: querySearch,
        });

        console.log('v2/search/getProjectPartners Response: ', response.data);

        let searchData = response.data.data;
        searchData.data.type = 'S';
        commit('set_projects', {
          data: searchData.data,
          meta: searchData.meta,
        });
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  async getOfficialPartnerAreas({ commit, state, dispatch }, { query, params }) {
    let self = this;

    if (query) await dispatch('loadRouteQuery', { query: query, params: params });

    query = Object.assign({}, query, {
      page: state.page,
      listing_type: state.listingType,
      query: state.searchTerm,
      property_type_id: state.propertyTypeId,
    });

    if (!query.searchTerm && (!query.sort_by || query.sort_by === '1')) {
      if (!state.seed) {
        dispatch('generateSearchSeed');
      }
    } else {
      commit('SET_SEED', null);
    }

    let querySearch = JSON.parse(JSON.stringify(query));

    console.log('v2/search/getOfficialPartnerAreas Query: ', querySearch);

    await new Promise(async function (resolve, reject) {
      try {
        querySearch = Object.assign({}, querySearch, {
          paginate: 30,
          seed: state.seed,
        });
        let response = await self.$http.get('/api/v2/official_partners/areas', {
          params: querySearch,
        });

        console.log('v2/search/getOfficialPartnerAreas Response: ', response.data);

        let searchData = response.data;
        commit('SET_OFFICIAL_PARTNER_AREAS_DATA', searchData.data);
        commit('SET_OFFICIAL_PARTNER_AREAS_META', searchData.meta);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  },

  async getDiscountListing({ commit, state, rootState }) {
    let self = this;
    let query = {
      page: state.page,
      listing_type: rootState.v2.search.listingType,
      discount: true,
    };

    console.log('v2/search/getDiscountListing Query: ', query);

    let allPromises = [];
    let querySearch = JSON.parse(JSON.stringify(query));
    allPromises.push(
      new Promise(async function (resolve, reject) {
        try {
          querySearch = Object.assign({}, querySearch, {
            paginate: 30,
            seed: state.seed,
          });
          let response = await self.$http.get('/api/v2/search', {
            params: querySearch,
          });
          console.log('v2/search/getDiscountListing Result: ', response.data.data);
          commit('set_listings', {
            data: response.data.data.data,
            meta: response.data.data.meta,
          });
          resolve(response);
        } catch (e) {
          reject(e);
        }
      }),
    );

    await Promise.all(allPromises);
  },
  async getSearchListing({ commit, state, dispatch, rootState }, { query, params }) {
    let self = this;

    if (query) await dispatch('loadRouteQuery', { query: query, params: params });

    query = Object.assign({}, query, {
      page: state.page,
      listing_type: state.listingType,
      query: state.searchTerm,
      property_type_id: state.propertyTypeId,
    });

    if (!query.searchTerm && (!query.sort_by || query.sort_by === '1')) {
      if (!state.seed) {
        dispatch('generateSearchSeed');
      }
    } else {
      commit('SET_SEED', null);
    }

    // For User Listing
    if (params.userUuid) {
      query = Object.assign({}, query, {
        user_uuid: params.userUuid,
      });
    }

    // For Official Partner Area Listing
    if (params.areaUrl) {
      query = Object.assign({}, query, {
        official_partner_area_uuid: rootState.v2.officialPartner.areaDetail.uuid,
      });
    }

    if (state.activePropertyTypes && state.activePropertyTypes.length > 0) {
      let ids = [];
      for (let propertyType of state.activePropertyTypes) {
        if (propertyType) ids.push(propertyType.id);
      }
      query.property_type_id = ids;
    } else {
      delete query.property_type_id;
    }
    delete query.property_category; // Doesn't need to send this parameter anymore to API.

    if (state.selectedCities && state.selectedCities.length > 0) {
      let ids = [];
      for (let city of state.selectedCities) {
        if (city) ids.push(city.id);
      }
      query.city_ids = ids;
    } else {
      delete query.city_ids;
    }

    console.log('v2/search/getSearchListing Query: ', query);

    let allPromises = [];
    if (state.listingType === 'R' && !query.no_premier) {
      let queryPremier = JSON.parse(JSON.stringify(query));
      allPromises.push(
        new Promise(async function (resolve, reject) {
          try {
            queryPremier = Object.assign({}, queryPremier, {
              paginate: 2,
              premier: true,
              seed: state.seed,
            });
            let response = await self.$http.get('/api/v2/search', {
              params: queryPremier,
            });
            commit('set_premier_listings', {
              data: response.data.data.data,
            });
            resolve(response);
          } catch (e) {
            reject(e);
          }
        }),
      );
    }

    let querySearch = JSON.parse(JSON.stringify(query));
    allPromises.push(
      new Promise(async function (resolve, reject) {
        try {
          querySearch = Object.assign({}, querySearch, {
            paginate: 15,
            seed: state.seed,
          });
          let response = await self.$http.get('/api/v2/search', {
            params: querySearch,
          });
          commit('set_listings', {
            data: response.data.data.data,
            meta: response.data.data.meta,
          });
          resolve(response);
        } catch (e) {
          reject(e);
        }
      }),
    );

    // if (this.page === 1) {
    //   await commit('set_geo_json', []);
    //   commit('getGeoJsonListings', query);
    // }

    await Promise.all(allPromises);
  },
  async getSearchListingPartner({ commit, state, dispatch }, { query, params }) {
    let self = this;

    if (query) await dispatch('loadRouteQuery', { query: query, params: params });

    query = Object.assign({}, query, {
      page: state.page,
      listing_type: state.listingType,
      seed: state.seed,
      partner_name: params.listingPartnerUrl,
    });

    if (!query.searchTerm && (!query.sort_by || query.sort_by === '1')) {
      if (!state.seed) {
        dispatch('generateSearchSeed');
      }
    } else {
      commit('SET_SEED', null);
    }

    console.log('v2/search/getSearchListingPartner Query: ', query);

    try {
      let response = await self.$http.get('/api/v2/search', {
        params: query,
      });
      console.log('v2/search/getSearchListingPartner Result: ', response.data.data);
      commit('set_listings', {
        data: response.data.data.data,
        meta: response.data.data.meta,
      });
    } catch (e) {
      console.log('ERROR getSearchListingPartner in v2/search.js: ', e);
    }
  },
  async getGeoJsonListings({ state, commit }, query) {
    query = Object.assign({}, query, {
      page: state.page,
      listing_type: state.listingType,
    });

    let response = await this.$http.get('/api/v2/search/geo-json', {
      params: query,
    });

    commit('set_geo_json', response.data.data);
  },
  async getShortlists({ commit }) {
    await this.$http.get('/api/v2/search/shortlists').then((response) => {
      commit('set_shortlists', response.data.data);
    });
  },
  async getSimilar(_, { uuid, listingType, mq }) {
    console.log('mqnyaaaaa aa', mq);
    let response = await this.$http.get('/api/v2/search', {
      params: {
        listing_type: listingType,
        listing_id: uuid,
        paginate: mq === 'xs' ? 4 : 3,
      },
    });
    return response.data.data.data;
  },
  async getCityById(_, cityId) {
    let response = await this.$http.get('/api/v2/search/city/' + cityId);

    return response.data;
  },
  async getCountListing() {
    let response = await this.$http.get('/api/v2/search/count-listing');
    return response.data;
  },
  async loadRouteQuery({ state, rootState, dispatch, commit }, { query, params }) {
    console.log('v2/search/loadRouteQuery Query: ', query);
    console.log('v2/search/loadRouteQuery Params: ', params);

    await dispatch('loadSearchTermFromRoute', { query: query, params: params });

    if (query.category_filter_id) {
      commit('SET_SEARCH_TERM_ID', query.category_filter_id);
    } else {
      commit('SET_SEARCH_TERM_ID', null);
    }

    if (query.category) {
      commit('SET_SEARCH_TERM_CATEGORY', query.category);
    } else {
      commit('SET_SEARCH_TERM_CATEGORY', null);
    }

    if (query.page) {
      commit('SET_PAGE', query.page);
    } else {
      commit('SET_PAGE', 1);
    }

    await dispatch('loadListingTypeFromRoute', { query: query, params: params });

    if (query.rent_period) {
      let rentPeriod = rootState.v2.masters.rentPeriods.find(function (item) {
        return item.id === parseInt(query.rent_period);
      });

      if (rentPeriod) commit('SET_RENT_PERIOD', rentPeriod);
    } else {
      commit('SET_RENT_PERIOD', {
        id: null,
        name: null,
      });
    }

    if (query.rent_price) {
      commit('SET_RENT_PRICE', query.rent_price);
    } else {
      commit('SET_RENT_PRICE', {
        id: null,
        name: null,
      });
    }

    await dispatch('loadSelectedCitiesFromRoute', { query: query });

    await dispatch('loadPropertyTypeFromRoute', { query: query, params: params });

    if (query.rent_type_id && Array.isArray(query.rent_type_id)) {
      commit('SET_RENT_TYPE_ID', query.rent_type_id);
    } else {
      let rentTypeId = [];
      if (query.rent_type_id) {
        await dispatch('v2/masters/getRentTypeById', query.rent_type_id, { root: true });
        rentTypeId.push(query.rent_type_id);
      }
      commit('SET_RENT_TYPE_ID', rentTypeId);
    }

    if (query.range_price) {
      commit('SET_RANGE_PRICE', parseInt(query.range_price));

      let rangePriceSelected = state.rangePrices.find(function (item) {
        return item.id === state.rangePrice;
      });

      commit('SET_RANGE_PRICE_NAME', rangePriceSelected ? rangePriceSelected.name : '');
    } else {
      commit('SET_RANGE_PRICE', 0);
      commit('SET_RANGE_PRICE_NAME', '');
    }

    if (query.building_size_min) {
      commit('SET_BUILDING_SIZE_MIN', query.building_size_min);
    } else {
      commit('SET_BUILDING_SIZE_MIN', null);
    }

    if (query.building_size_max) {
      commit('SET_BUILDING_SIZE_MAX', query.building_size_max);
    } else {
      commit('SET_BUILDING_SIZE_MAX', null);
    }

    if (query.price_min) {
      commit('SET_RENT_PRICE_MIN', 'Rp ' + query.price_min);
    } else {
      commit('SET_RENT_PRICE_MIN', null);
    }

    if (query.price_max) {
      commit('SET_RENT_PRICE_MAX', 'Rp ' + query.price_max);
    } else {
      commit('SET_RENT_PRICE_MAX', null);
    }

    if (query.sell_price_min) {
      commit('SET_SELL_PRICE_MIN', 'Rp ' + query.sell_price_min);
    } else {
      commit('SET_SELL_PRICE_MIN', null);
    }

    if (query.sell_price_max) {
      commit('SET_SELL_PRICE_MAX', 'Rp ' + query.sell_price_max);
    } else {
      commit('SET_SELL_PRICE_MAX', null);
    }

    if (query.property_category) {
      commit('SET_SELECTED_CATEGORY_ID', parseInt(query.property_category));

      let category = state.categories.find(function (item) {
        return item.id === state.selectedCategoryId;
      });

      if (category) commit('SET_CATEGORY', category);
    } else {
      commit('SET_SELECTED_CATEGORY_ID', null);
      commit('SET_CATEGORY', { id: null, name: 'all' });
    }

    if (query.done_estimation) {
      commit('SET_SELECTED_PROJECT_STATUS_ID', parseInt(query.done_estimation));

      let projectStatus = state.projectStatuses.find(function (item) {
        return item.id === state.selectedProjectStatusId;
      });

      if (projectStatus) commit('SET_PROJECT_STATUS', projectStatus);
    } else {
      commit('SET_SELECTED_PROJECT_STATUS_ID', null);
      commit('SET_PROJECT_STATUS', { id: null, name: 'all' });
    }

    if (query.sort_by) {
      let sortBy = state.sortByOptions.find(function (item) {
        return item.id === parseInt(query.sort_by);
      });

      commit('SET_SORT_BY', sortBy);
    } else {
      commit('SET_SORT_BY', { id: 1, name: 'relevance' });
    }

    await dispatch('loadSearchTypeFromRoute', {
      query: query,
      params: params,
    });

    await dispatch('loadIsOfficialPartnerFromRoute', {
      query: query,
      params: params,
    });

    if (query.pano_required) {
      commit('SET_PANO_REQUIRED', query.pano_required);
    } else {
      commit('SET_PANO_REQUIRED', false);
    }
  },
  async loadSearchTermFromRoute({ commit }, { query, params }) {
    if (params.query) {
      commit('SET_SEARCH_TERM', params.query.split('-').join(' '));
    } else if (query.query) {
      commit('SET_SEARCH_TERM', query.query);
    } else {
      commit('SET_SEARCH_TERM', null);
    }
  },
  async loadListingTypeFromRoute({ commit, dispatch }, { query, params }) {
    if (params && params.listing_type) {
      let listingType = await dispatch('convertListingTypeStringToId', params.listing_type);
      commit('SET_LISTING_TYPE', listingType);
    } else if (query.listing_type) {
      commit('SET_LISTING_TYPE', query.listing_type);
    }
  },
  async loadPropertyTypeFromRoute({ commit, rootState }, { query, params }) {
    let propertyTypeName = null;
    if (query.property_type_id) {
      let queryPropertyTypes = JSON.parse(decodeURIComponent(query.property_type_id));
      let activePropertyTypes = [];
      if (queryPropertyTypes && queryPropertyTypes.length > 0) {
        for (let propertyType of rootState.v2.masters.propertyTypes) {
          if (queryPropertyTypes.includes(propertyType.id)) {
            activePropertyTypes.push(propertyType);
          }
        }
      }
      commit('SET_ACTIVE_PROPERTY_TYPES', activePropertyTypes);
      return;
    } else if (params && params.propertyType) {
      propertyTypeName = params.propertyType;
    } else {
      commit('SET_PROPERTY_TYPE_ID', null);
      return;
    }

    let propertyType = null;
    if (propertyTypeName && propertyTypeName.toLowerCase() !== 'semua') {
      propertyType = await rootState.v2.masters.propertyTypes.find(function (item) {
        return item && item.name
          ? item.name.toLowerCase() === propertyTypeName.toLowerCase()
          : false;
      });
      if (propertyType) propertyType = JSON.parse(JSON.stringify(propertyType));
    }
    commit('SET_PROPERTY_TYPE_ID', propertyType ? propertyType.id : null);
  },
  async loadSelectedCitiesFromRoute({ commit, rootState }, { query }) {
    if (query.city_ids) {
      let querySelectedCityIds = JSON.parse(decodeURIComponent(query.city_ids));
      let selectedCities = [];
      if (querySelectedCityIds && querySelectedCityIds.length > 0) {
        for (let city of rootState.v2.masters.cities) {
          if (querySelectedCityIds.includes(city.id)) {
            selectedCities.push(city);
          }
        }
      }
      commit('SET_SELECTED_CITIES', selectedCities);
    } else {
      commit('SET_SELECTED_CITIES', []);
    }
  },
  // eslint-disable-next-line no-unused-vars
  loadSearchTypeFromRoute({ state, commit }, { query, params }) {
    if (query.search_type) {
      commit('SET_SEARCH_TYPE', parseInt(query.search_type));
    } else {
      // if (state.listingType === 'R') {
      //   commit('SET_SEARCH_TYPE', 2);
      // } else {
      //   commit('SET_SEARCH_TYPE', 1);
      // }

      commit('SET_SEARCH_TYPE', 2);
    }
    console.log('v2/search/loadSearchTypeFromRoute SEARCH TYPE: ', state.searchType);
  },
  // eslint-disable-next-line no-unused-vars
  loadIsOfficialPartnerFromRoute({ state, commit }, { query, params }) {
    if (query.is_official_partner) {
      commit('SET_IS_OFFICIAL_PARTNER', query.is_official_partner.toLowerCase() === 'true');
    } else {
      commit('SET_IS_OFFICIAL_PARTNER', false);
    }
    console.log(
      'v2/search/loadIsOfficialPartnerFromRoute IS OFFICIAL PARTNER: ',
      state.isOfficialPartner,
    );
  },
  // eslint-disable-next-line no-unused-vars
  async goToSearchPage({ state, rootState }, { router, query, params }) {
    try {
      // Kalau parameter query tidak dimasukkan, maka fungsi ini akan load isi query dari state
      if (!query) {
        query = {};

        if (state.listingType) query.listing_type = state.listingType;
        if (state.page && state.page !== 1 && state.page !== '1') {
          query.page = state.page;
        }
        if (state.buildingSizeMin) query.building_size_min = state.buildingSizeMin;
        if (state.buildingSizeMax) query.building_size_max = state.buildingSizeMax;
        if (state.selectedCategoryId) query.property_category = state.selectedCategoryId;
        if (state.sortBy && state.sortBy.id) query.sort_by = state.sortBy.id;
        if (state.panoRequired) query.pano_required = state.panoRequired;
        if (state.listingType === 'R') {
          if (state.rentPeriod && state.rentPeriod.id) query.rent_period = state.rentPeriod.id;
          if (state.rentPrice && state.rentPrice.id) query.rent_price = state.rentPrice.id;
          if (state.rentTypeId && JSON.stringify(state.rentTypeId) !== '[]')
            query.rent_type_id = state.rentTypeId;
          if (state.rangePrice) query.range_price = state.rangePrice;
          if (state.rentPriceMin) {
            let rentPriceMin = state.rentPriceMin.replace('Rp', '').trim();
            if (rentPriceMin) query.price_min = rentPriceMin;
          }
          if (state.rentPriceMax) {
            let rentPriceMax = state.rentPriceMax.replace('Rp', '').trim();
            if (rentPriceMax) query.price_max = rentPriceMax;
          }
        } else if (state.listingType === 'S') {
          if (state.sellPriceMin) {
            let sellPriceMin = state.sellPriceMin.replace('Rp', '').trim();
            if (sellPriceMin) query.sell_price_min = sellPriceMin;
          }
          if (state.sellPriceMax) {
            let sellPriceMax = state.sellPriceMax.replace('Rp', '').trim();
            if (sellPriceMax) query.sell_price_max = sellPriceMax;
          }
        }

        if (state.category && state.category.id) query.property_category = state.category.id;
        if (state.activePropertyTypes && state.activePropertyTypes.length > 0) {
          let ids = [];
          for (let propertyType of state.activePropertyTypes) {
            if (propertyType) ids.push(propertyType.id);
          }
          query.property_type_id = encodeURIComponent(JSON.stringify(ids));
        } else {
          delete query.property_type_id;
        }
        if (state.selectedCities && state.selectedCities.length > 0) {
          let ids = [];
          for (let city of state.selectedCities) {
            if (city) ids.push(city.id);
          }
          query.city_ids = encodeURIComponent(JSON.stringify(ids));
        } else {
          delete query.city_ids;
        }

        if (state.isOfficialPartner) {
          query.is_official_partner = true;
        } else {
          delete query.is_official_partner;
        }

        // Khusus halaman search user listing
        if (params.userUuid) {
          if (state.propertyTypeId) {
            query.property_type_id = state.propertyTypeId;
          }
        }
      }

      let path = '';

      // Halaman search user listing
      if (params.userUuid) {
        path = '/user/listing/' + params.userUuid;
      }
      // Halaman search listing partner
      else if (params.listingPartnerUrl) {
        path = '/' + params.listingPartnerUrl;
      }
      // Halaman search official partner area
      else if (params.areaUrl) {
        path = '/' + params.partnerUrl + '/' + params.areaUrl;
      }
      // Halaman search biasa
      else {
        let listing_type = state.listingType === 'S' ? 'beli' : 'sewa';
        path = '/' + listing_type + '/search/';

        let propertyType = null;
        // if (state.propertyTypeId) {
        //   propertyType = await rootState.v2.masters.propertyTypes.find(function(item) {
        //     return item.id === Number(state.propertyTypeId);
        //   });
        //   if (propertyType) propertyType = JSON.parse(JSON.stringify(propertyType));
        // }
        if (state.activePropertyTypes && state.activePropertyTypes.length > 0) {
          propertyType = state.activePropertyTypes[0];
        }
        if (propertyType && propertyType.name) {
          path += propertyType.name.toLowerCase() + '/';
        } else {
          path += 'semua/';
        }
      }

      let useSearchTerm = false;
      if (state.searchTerm) {
        if (state.listingType === 'R') {
          useSearchTerm = true;
        } else if (state.listingType === 'S') {
          if (state.searchType === 1) {
            if (state.category && state.searchTermId) {
              useSearchTerm = true;
            }
          } else {
            useSearchTerm = true;
          }
        }
      }

      if (useSearchTerm) {
        if (state.searchTermCategory) query.category = state.searchTermCategory;
        if (state.searchTermId) query.category_filter_id = state.searchTermId;

        // Halaman search biasa
        if (!params.userUuid && !params.listingPartnerUrl) {
          path += encodeURI(state.searchTerm.split(' ').join('-').replace(/\//g, '')) + '/';
        }
        // Halaman search user listing dan search listing partner
        else {
          query.query = state.searchTerm;
        }
      } else {
        delete query.category;
        delete query.category_filter_id;
      }

      console.log('CALL goToSearchPage in v2/search.js PATH: ', path);
      console.log('CALL goToSearchPage in v2/search.js QUERY: ', query);
      await router
        .push({
          path: path,
          query: query,
        })
        .catch((e) => {
          throw e;
        });
    } catch (e) {
      console.log('ERROR goToSearchPage in v2/search.js: ', e);
      throw e;
    }
  },
  setSelectedCategoryId({ commit }, { payload, query }) {
    if (query && query.property_category !== payload) {
      commit('SET_SELECTED_CATEGORY_ID', payload);

      try {
        query = Object.assign({}, query, { property_category: payload });
        delete query.rent_period;
        delete query.rent_type_id;
        delete query.property_type_id;
        delete query.page;
        return query;
      } catch (e) {
        console.log('ERROR setSelectedCategoryId in v2/search.js: ', e);
      }
    }

    return null;
  },
  setSelectedProjectStatusId({ commit }, { payload, query }) {
    if (query && query.done_estimation !== payload) {
      commit('SET_SELECTED_PROJECT_STATUS_ID', payload);

      try {
        query = Object.assign({}, query, { done_estimation: payload });
        delete query.page;
        return query;
      } catch (e) {
        console.log('ERROR setSelectedProjectStatusId in v2/search.js: ', e);
      }
    }

    return null;
  },
  setRentPeriod({ commit }, { payload, query }) {
    let rentPeriodId = payload ? payload.id : null;
    if (query && query.rent_period !== rentPeriodId) {
      commit('SET_RENT_PERIOD', payload);

      try {
        query = Object.assign({}, query, {
          rent_period: rentPeriodId,
        });
        delete query.page;
        if (!rentPeriodId) {
          delete query.price_min;
          delete query.price_max;
        }
        return query;
      } catch (e) {
        console.log('ERROR setRentPeriod in v2/search.js: ', e);
      }
    }

    return null;
  },
  setRangePrice({ commit }, { payload, query }) {
    if (payload !== null) payload = parseInt(payload);
    let rangePrice = payload > 0 ? payload : null;
    if (query && query.range_price !== rangePrice) {
      commit('SET_RANGE_PRICE', payload);

      try {
        query = Object.assign({}, query, {
          range_price: rangePrice,
        });
        delete query.page;
        return query;
      } catch (e) {
        console.log('ERROR setRangePrice in v2/search.js: ', e);
      }
    }

    return null;
  },
  setRentPrice({ commit }, { payload, query }) {
    state.rentPrice = payload;

    if (query && query.rent_price !== payload) {
      commit('SET_RENT_PRICE', payload);

      try {
        query = Object.assign({}, query, { rent_price: state.rentPrice });
        delete query.page;
        return query;
      } catch (e) {
        console.log('ERROR setRentPrice in v2/search.js: ', e);
      }
    }

    return null;
  },
  setRentTypeId({ commit }, { payload, query }) {
    if (query) {
      let rent_type_id = query.rent_type_id;
      if (!rent_type_id) {
        rent_type_id = [];
      }

      if (JSON.stringify(rent_type_id) !== JSON.stringify(payload)) {
        commit('SET_RENT_TYPE_ID', payload);

        try {
          query = Object.assign({}, query, { rent_type_id: payload });
          delete query.page;
          return query;
        } catch (e) {
          console.log('ERROR setRentTypeId in v2/search.js: ', e);
        }
      }
    }
    return null;
  },
  async setPropertyTypeId({ commit }, { payload, query }) {
    if (query) {
      let property_type_id = query.property_type_id;
      if (!property_type_id) {
        property_type_id = [];
      }

      if (property_type_id && JSON.stringify(property_type_id) !== JSON.stringify(payload)) {
        commit('SET_PROPERTY_TYPE_ID', payload);

        try {
          query = Object.assign({}, query, { property_type_id: payload });
          delete query.page;
          return query;
        } catch (e) {
          console.log('ERROR setPropertyTypeId in v2/search.js: ', e);
        }
      }
    }

    return null;
  },
  onBuildingSizeChange({ state }, { query }) {
    let buildingSizeMin = state.buildingSizeMin;
    let buildingSizeMax = state.buildingSizeMax;

    try {
      if (buildingSizeMin)
        query = Object.assign({}, query, {
          building_size_min: buildingSizeMin,
        });
      else delete query.building_size_min;
      if (buildingSizeMax)
        query = Object.assign({}, query, {
          building_size_max: buildingSizeMax,
        });
      else delete query.building_size_max;
      delete query.page;
      return query;
    } catch (e) {
      console.log('ERROR onBuildingSizeChange in v2/search.js: ', e);
    }

    return null;
  },
  onRentPriceChange({ state }, { query }) {
    let rentPriceMin = state.rentPriceMin ? state.rentPriceMin.replace('Rp', '').trim() : null;
    let rentPriceMax = state.rentPriceMax ? state.rentPriceMax.replace('Rp', '').trim() : null;
    rentPriceMin = rentPriceMin ? rentPriceMin : null;
    rentPriceMax = rentPriceMax ? rentPriceMax : null;

    try {
      if (query) {
        let queryPriceMin = query.price_min ? query.price_min : null;
        let queryPriceMax = query.price_max ? query.price_max : null;
        if (rentPriceMin !== queryPriceMin || rentPriceMax !== queryPriceMax) {
          if (rentPriceMin)
            query = Object.assign({}, query, {
              price_min: rentPriceMin,
            });
          else delete query.price_min;
          if (rentPriceMax)
            query = Object.assign({}, query, {
              price_max: rentPriceMax,
            });
          else delete query.price_max;
          delete query.page;
          return query;
        }
      }
      return null;
    } catch (e) {
      console.log('ERROR onRentPriceChange in v2/search.js: ', e);
    }

    return null;
  },
  onSellPriceChange({ state }, { query }) {
    let sellPriceMin = state.sellPriceMin ? state.sellPriceMin.replace('Rp', '').trim() : null;
    let sellPriceMax = state.sellPriceMax ? state.sellPriceMax.replace('Rp', '').trim() : null;
    sellPriceMin = sellPriceMin ? sellPriceMin : null;
    sellPriceMax = sellPriceMax ? sellPriceMax : null;

    try {
      if (query) {
        let queryPriceMin = query.sell_price_min ? query.sell_price_min : null;
        let queryPriceMax = query.sell_price_max ? query.sell_price_max : null;
        if (sellPriceMin !== queryPriceMin || sellPriceMax !== queryPriceMax) {
          if (sellPriceMin)
            query = Object.assign({}, query, {
              sell_price_min: sellPriceMin,
            });
          else delete query.sell_price_min;
          if (sellPriceMax)
            query = Object.assign({}, query, {
              sell_price_max: sellPriceMax,
            });
          else delete query.sell_price_max;
          delete query.page;
          return query;
        }
      }
      return null;
    } catch (e) {
      console.log('ERROR onSellPriceChange in v2/search.js: ', e);
    }

    return null;
  },
  setSortBy({ commit }, { payload, query }) {
    if (query && payload !== payload.id) {
      commit('SET_SORT_BY', payload);

      try {
        query = Object.assign({}, query, { sort_by: payload.id });
        delete query.page;
        return query;
      } catch (e) {
        console.log('ERROR setSortBy in v2/search.js: ', e);
      }
    }

    return null;
  },
  setListingType({ state, commit }, { payload, query, clearRoute = true }) {
    console.log('v2/search/setListingType Payload: ', payload);
    if (state.listingType !== payload) {
      commit('SET_LISTING_TYPE', payload);

      query.listing_type = payload;
      if (clearRoute) {
        // Clear all other parameters from route query except for search term and listing type.
        let newQuery = {};

        delete query.property_category;
        delete query.page;
        if (query.category) newQuery = Object.assign({}, newQuery, { category: query.category });
        if (query.category_filter_id)
          newQuery = Object.assign({}, newQuery, { category_filter_id: query.category_filter_id });

        return newQuery;
      } else {
        if (payload === 'S' && query) {
          delete query.rent_period;
          delete query.rent_type_id;
        }
        delete query.page;
        return query;
      }
    }

    return null;
  },
  setSearchType({ state, commit }, { payload, query }) {
    if (query && query.search_type !== payload) {
      commit('CLEAR_FILTER');

      if (state.listingType === 'R') {
        commit('SET_SEARCH_TYPE', 2);
      } else {
        commit('SET_SEARCH_TYPE', payload);
      }

      try {
        query = Object.assign({}, query, { search_type: payload });
        delete query.property_category;
        delete query.building_size_min;
        delete query.building_size_max;
        delete query.pano_required;
        delete query.sort_by;
        delete query.done_estimation;
        delete query.page;
        return query;
      } catch (e) {
        console.log('ERROR setSearchType in v2/search.js: ', e);
      }
    }

    return null;
  },
  setPanoRequired({ commit }, { payload, query }) {
    if (query && query.pano_required !== payload) {
      commit('SET_PANO_REQUIRED', payload);

      try {
        query = Object.assign({}, query, { pano_required: payload });
        delete query.page;
        return query;
      } catch (e) {
        console.log('ERROR setPanoRequired in v2/search.js: ', e);
      }
    }

    return null;
  },
  // eslint-disable-next-line no-unused-vars
  setIsOfficialPartner({ state, commit }, { payload, query }) {
    if (query && query.is_official_partner !== payload) {
      commit('CLEAR_FILTER');
      commit('SET_IS_OFFICIAL_PARTNER', payload);

      try {
        query = Object.assign({}, query, { is_official_partner: payload });
        delete query.range_price;
        delete query.rent_period;
        delete query.property_category;
        delete query.building_size_min;
        delete query.building_size_max;
        delete query.price_min;
        delete query.price_max;
        delete query.sell_price_min;
        delete query.sell_price_max;
        delete query.pano_required;
        delete query.sort_by;
        delete query.done_estimation;
        delete query.page;
        return query;
      } catch (e) {
        console.log('ERROR setIsOfficialPartner in v2/search.js: ', e);
      }
    }

    return null;
  },
  generateSearchSeed({ commit }) {
    let seed = Math.random().toString(36).slice(2);
    commit('SET_SEED', seed);
    console.log('GENERATE NEW SEARCH SEED: ', seed);
  },
};

// load module store di dalem folder ./search
// const requireContext = require.context('./search', false, /.*\.js$/);
// const modulesTemp = requireContext
//   .keys()
//   .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
//   .reduce((modules, [name, module]) => {
//     if (module.namespaced === undefined) {
//       module.namespaced = true;
//     }
//     return { ...modules, [name]: module };
//   }, {});
// export const modules = modulesTemp;
