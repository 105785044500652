const mapKey =
  process.env.NODE_ENV === 'production'
    ? 'AIzaSyAg8OArbG2qQg19UE5m7Zux_RHWaEe70f0'
    : 'AIzaSyCrYqjA_5Jnbm_qygdHMY7vWgCvcMsoOI0';

export const headConfig = {
  title: 'Rentfix.com',
  meta: [
    { charset: 'utf-8' },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    {
      hid: 'keywords',
      name: 'keywords',
      content:
        'sewa rumah, sewa apartemen, sewa ruko, sewa kantor, sewa gudang, sewa coworking space, ' +
        'sewa ruang pertemuan, rumah dijual, apartemen dijual, ruko dijual, kantor dijual, gudang dijual, ' +
        'beli rumah, beli apartemen, beli ruko, beli kantor, beli gudang, Rentfix.com, Rentfix, ' +
        'marketplace real estate, real estate indonesia',
    },
    { name: 'robots', content: 'Index, Follow' },
    { hid: 'og-fb-app_id', property: 'fb:app_id', content: '736609587748928' },
    { name: 'facebook-domain-verification', content: 'ed8gab0q660i862pmh0kgh8wtu8hel' },
  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '/img/icons/favicon-16x16.png' },
    { rel: 'manifest', href: '/manifest.json' },
    { rel: 'preconnect', href: 'https://cdnjs.cloudflare.com' },
    {
      rel: 'stylesheet',
      href: 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css',
      async: true,
      defer: true,
    },
    { rel: 'dns-prefetch', href: 'https://www.googletagmanager.com' },
    { rel: 'dns-prefetch', href: 'https://www.google-analytics.com' },
    { rel: 'dns-prefetch', href: 'https://www.facebook.com' },
    { rel: 'preconnect', href: 'https://www.google.com' },
    { rel: 'preconnect', href: 'https://www.google.co.id' },
    { rel: 'preconnect', href: 'https://maps.googleapis.com' },
    { rel: 'preconnect', href: 'https://googleads.g.doubleclick.net' },
    { rel: 'preconnect', href: 'https://apiv2.rentfix.com', crossorigin: true },
    { rel: 'preconnect', href: 'https://stats.pusher.com' },
    { rel: 'preconnect', href: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com' },
    { rel: 'preconnect', href: 'https://embed.tawk.to' },
    {
      rel: 'preload',
      href: 'https://connect.facebook.net/en_US/fbevents.js',
      as: 'script',
      type: 'application/x-javascript',
    },
    {
      rel: 'preload',
      href: 'https://www.google-analytics.com/analytics.js',
      as: 'script',
      type: 'text/javascript',
    },
    {
      rel: 'preload',
      href: 'https://www.googleadservices.com/pagead/conversion_async.js',
      as: 'script',
      type: 'text/javascript',
    },
    // {
    //   rel: 'preload',
    //   href: 'https://www.googletagmanager.com/gtag/js?id=AW-828231018&l=dataLayer',
    //   as: 'script',
    //   type: 'application/javascript',
    // },
    {
      rel: 'preload',
      href: 'https://s3-ap-southeast-1.amazonaws.com/rentfix/fonts/ionicons/ionicons.ttf?v=2.0.0',
      as: 'font',
      crossorigin: true,
    },
    {
      rel: 'preload',
      href: 'https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/fonts/poppinswoff/Poppins-Bold.woff2',
      as: 'font',
      crossorigin: true,
    },
    {
      rel: 'preload',
      href: 'https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/fonts/poppinswoff/Poppins-Medium.woff2',
      as: 'font',
      crossorigin: true,
    },
    {
      rel: 'preload',
      href: 'https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/fonts/poppinswoff/Poppins-Regular.woff2',
      as: 'font',
      crossorigin: true,
    },
    {
      rel: 'preload',
      href: 'https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/fonts/poppinswoff/Poppins-SemiBold.woff2',
      as: 'font',
      crossorigin: true,
    },
    { rel: 'alternate', href: 'android-app://com.rentfix.www/https/rentfix.com' },
    { rel: 'alternate', href: 'ios-app://1378618675/https/rentfix.com' },
  ],
  script: [
    {
      src: 'https://maps.googleapis.com/maps/api/js?key=' + mapKey + '&libraries=places',
      defer: true,
    },
    {
      src: 'https://www.google.com/recaptcha/api.js?render=explicit',
      async: true,
      defer: true,
    },
  ],
};
