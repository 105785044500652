const requireContext = require.context('./initiate', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  startDate: null,
  duration: 1,
  rentPeriod: null,
  listing: null,
  listingUuid: null,
  loading: false,
  startTime: 0,
  phone: '',
  customAgreement: {},

  //data modal
  disabledDates: [],
  moveDate: null,
  localDuration: 1,
  periodName: null,
  localPeriodId: null,
  localPhone: '',
  selectedPeriod: null,
});

export const mutations = {
  SET_DISABLE_DATES(state, payload) {
    state.disabledDates = payload;
  },
  SET_LISTING(state, payload) {
    state.listing = payload;
  },
  SET_LISTING_UUID(state, payload) {
    state.listingUuid = payload;
  },
  SET_SELECTED_PERIOD(state, payload) {
    state.selectedPeriod = payload;
  },
  SET_LOCAL_PHONE(state, payload) {
    state.localPhone = payload;
  },
  SET_PERIOD_NAME(state, payload) {
    state.periodName = payload;
  },
  SET_LOCAL_PERIOD_ID(state, payload) {
    state.localPeriodId = payload;
  },
  SET_LOCAL_DURATION(state, payload) {
    state.localDuration = payload;
  },
  SET_CUSTOM_AGREEMENT(state, payload) {
    state.customAgreement = payload;
  },

  SET_MOVE_DATE(state, payload) {
    state.moveDate = payload;
  },

  SET_START_TIME(state, payload) {
    state.startTime = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_START_DATE(state, payload) {
    state.startDate = payload;
  },
  SET_DURATION(state, payload) {
    state.duration = payload;
  },
  SET_RENT_PERIOD(state, payload) {
    state.rentPeriod = payload;
  },
  SET_PHONE(state, payload) {
    state.phone = payload;
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_DISABLE_DATES', []);
    commit('SET_LISTING_UUID', null);
    commit('SET_MOVE_DATE', null);
    commit('SET_LOCAL_DURATION', 1);
    commit('SET_PERIOD_NAME', null);
    commit('SET_LOCAL_PERIOD_ID', null);
    commit('SET_LOCAL_PHONE', '');
    commit('SET_SELECTED_PERIOD', null);
    commit('SET_LISTING', null);
    commit('SET_START_TIME', 0);
  },

  async submit({ commit, state }, listingUuid) {
    try {
      commit('SET_LOADING', true);
      let moveTime = null;
      if (state.rentPeriod == 5) {
        moveTime = state.startTime + '.00';
      }
      let postData = {
        start_date: this.$date.format(state.startDate, 'dd-MM-yyyy'),
        duration: state.duration,
        rent_period_id: state.rentPeriod,
        listing_uuid: listingUuid,
        move_time: moveTime,
        phone: state.phone,
      };
      if (state.promo.promoDetail) {
        postData.promo_uuid = state.promo.promoDetail.uuid;
      }
      if (state.customAgreement && state.customAgreement.uuid) {
        postData.custom_agreement_uuid = state.customAgreement.uuid;
      }
      const { data } = await this.$http.post('/api/v2/rent_transaction/initiate', postData);
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async isBookingAllowed({}, listingUuid) {
    try {
      let response = await this.$http.get(
        '/api/v2/rent_transaction/is_allowed?listing_uuid=' + listingUuid,
      );
      return response.data.data;
    } catch (e) {
      throw e;
    }
  },

  async getBlockedDate(_, requestData) {
    try {
      let response = await this.$http.get(
        '/api/v2/rent_transaction/get_blocked_date/' + requestData.uuid,
        {
          params: {
            booking_uuid: requestData.booking_uuid,
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log('ERROR getBlockedDate: ', e);
    }
  },

  async checkRequestBooking({ state, commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.get('/api/v2/rent_transaction/initiate_check', {
        params: {
          listing_uuid: state.listingUuid,
          rent_period_id: state.rentPeriod,
          start_date: this.$date.format(state.startDate, 'dd-MM-yyyy'),
          duration: state.duration,
        },
      });
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getInitData({ state, commit }) {
    try {
      commit('SET_LOADING', true);

      let moveTime = null;
      if (state.rentPeriod === 5) {
        moveTime = state.startTime + '.00';
      }
      let postData = {
        startDate: this.$date.format(state.startDate, 'dd-MM-yyyy'),
        duration: state.duration,
        rentPeriodId: state.rentPeriod,
        listingUuid: state.listingUuid,
        move_time: moveTime,
      };
      if (state.promo.promoDetail) {
        postData.promo_uuid = state.promo.promoDetail.uuid;
      }
      const { data } = await this.$http.get('/api/v2/booking_request/get_init_data', {
        params: postData,
      });
      commit('SET_LISTING', data.data.listing);
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
