const requireContext = require.context('./sect1', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;
export const state = () => ({
  selectedCountry: null,
  isTouched: false,
  openOwnership: false,
  openMap: false,
  openAmenities: false,
  openMedia: false,
  openBasicInfo: false,
  openDetail: false,
});

export const getters = {
  formData(state, getters) {
    const locationFormData = getters['location/formData'];
    const basicInfoFormData = getters['basicInfo/formData'];
    const mediaFormData = getters['media/formData'];
    const regionalFormData = getters['regionalListingForm/formData'];
    return Object.assign({}, locationFormData, basicInfoFormData, mediaFormData, regionalFormData);
  },
};

export const mutations = {
  SET_OPEN_OWNERSHIP(state, payload) {
    state.openOwnership = payload;
  },
  SET_OPEN_AMENITIES(state, payload) {
    state.openAmenities = payload;
  },
  SET_OPEN_BASIC_INFO(state, payload) {
    state.openBasicInfo = payload;
  },
  SET_OPEN_DETAIL(state, payload) {
    state.openDetail = payload;
  },
  SET_OPEN_MAP(state, payload) {
    state.openMap = payload;
  },
  SET_OPEN_MEDIA(state, payload) {
    state.openMedia = payload;
  },
  SET_LOADING_MAP(state, payload) {
    state.loadingMap = payload;
  },
  SET_IS_TOUCHED(state, payload) {
    state.isTouched = payload;
  },
  SET_SELECTED_COUNTRY(state, payload) {
    state.selectedCountry = payload;
  },
  RESTORE_INITIAL_STATE(state) {
    state.openOwnership = false;
    state.openAmenities = false;
    state.openMedia = false;
    state.openBasicInfo = false;
    state.openMap = false;
    state.propertyUuid = null;
    state.selectedCountry = null;
    state.isTouched = false;
  },
};

export const actions = {
  openAllHiddenForms({ commit }) {
    commit('SET_OPEN_OWNERSHIP', true);
    commit('SET_OPEN_AMENITIES', true);
    commit('SET_OPEN_BASIC_INFO', true);
    commit('SET_OPEN_MAP', true);
    commit('SET_OPEN_MEDIA', true);
    commit('SET_OPEN_DETAIL', true);
  },

  hideAllHiddenForms({ commit }) {
    commit('SET_OPEN_OWNERSHIP', false);
    commit('SET_OPEN_AMENITIES', false);
    commit('SET_OPEN_BASIC_INFO', false);
    commit('SET_OPEN_MAP', false);
    commit('SET_OPEN_MEDIA', false);
    commit('SET_OPEN_DETAIL', false);
  },
  async applyEditData({ dispatch }, { form, editData }) {
    await dispatch('location/applyEditData', {
      form,
      data: editData,
    });
    await dispatch('basicInfo/applyEditData', {
      form,
      data: editData,
    });
    await dispatch('media/applyEditData', {
      form,
      data: editData,
    });
  },
  restoreInitialState({ commit }) {
    commit('SET_IS_TOUCHED', false);
    commit('SET_SELECTED_COUNTRY', null);
    commit('location/RESTORE_INITIAL_STATE');
    commit('basicInfo/RESTORE_INITIAL_STATE');
    commit('media/RESTORE_INITIAL_STATE');
  },

  // eslint-disable-next-line no-unused-vars
  async applyBaseData({ commit, dispatch, state, getters }, { form, baseData }) {
    await dispatch('location/applyBaseData', {
      form,
      data: baseData,
    });
    await dispatch('basicInfo/applyBaseData', {
      form,
      data: baseData,
    });

    if (form === 'add') {
      commit('regionalListingForm/RESTORE_INITIAL_STATE');
      commit('regionalListingForm/SET_PROVINCES', baseData.provinces);
      commit('location/SET_SELECTED_PROPERTY_TYPE_CATEGORY_GROUP_LIST', 1);
      // if (state.location.officialPartnerAreas.length > 0) {
      //   commit(
      //     'location/SET_SELECTED_OFFICIAL_PARTNER_AREA',
      //     getters['location/allowedOfficialPartnerAreas'][0].uuid,
      //   );
      // }
    }

    commit('location/SET_DEFAULT_ADD_STATE');
    commit('basicInfo/SET_DEFAULT_VALUE');
  },
};
