const requireContext = require.context('./checkout', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  listingUuid: null,
  bookingInfo: null,
  freeReservationFee: false,
  loading: true,
});

export const getters = {
  formData(state, getters) {
    const billingInfoFormData = getters['billingInfo/formData'];
    return Object.assign(
      {
        listing_uuid: state.listingUuid,
      },
      billingInfoFormData,
    );
  },
};

export const mutations = {
  SET_LISTING_UUID(state, payload) {
    state.listingUuid = payload;
  },
  SET_BOOKING_INFO(state, payload) {
    state.bookingInfo = payload;
  },
  SET_FREE_RESERVATION(state, payload) {
    state.freeReservationFee = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
};

export const actions = {
  restoreInitialState({ commit, dispatch }) {
    commit('SET_LISTING_UUID', null);
    commit('SET_BOOKING_INFO', null);
    commit('SET_FREE_RESERVATION', false);
    dispatch('billingInfo/restoreInitialState');
  },

  async getData({ commit, state, dispatch }) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.get('/api/v2/checkout/get_data', {
        params: {
          listing_uuid: state.listingUuid,
        },
      });
      console.log('GET DATA', data.data);
      commit('SET_BOOKING_INFO', data.data.booking_info);
      commit('SET_FREE_RESERVATION', data.data.free_reservation_fee);
      dispatch('billingInfo/applyGetData', data.data.billing_info);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async submit({ commit, getters }, villageId) {
    try {
      commit('SET_LOADING', true);
      const formData = Object.assign(
        {
          village_id: villageId,
        },
        getters.formData,
      );
      let { data } = await this.$http.post('/api/v2/checkout/submit', formData);
      return data.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
