const requireContext = require.context('./rentTransaction', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  // listingUuid: null,
  // bookingInfo: null,
  // loading: true,
  sellTransactionUuid: null,
});

export const getters = {
  // formData(state, getters) {
  //   const billingInfoFormData = getters['billingInfo/formData'];
  //   return Object.assign(
  //     {
  //       listing_uuid: state.listingUuid,
  //     },
  //     billingInfoFormData,
  //   );
  // },
};

export const mutations = {
  SET_SELL_TRANSACTION_UUID(state, payload) {
    state.sellTransactionUuid = payload;
  },
  // SET_BOOKING_INFO(state, payload) {
  //   state.bookingInfo = payload;
  // },
  // SET_LOADING(state, payload) {
  //   state.loading = payload;
  // },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_SELL_TRANSACTION_UUID', null);
  },

  async cancel(_, uuid) {
    try {
      let { data } = await this.$http.post('/api/v2/rent_transaction/cancel', {
        uuid,
      });
      return data.data;
    } catch (e) {
      console.log('ERROR rentTransaction/cancel: ', e);
      throw e;
    }
  },

  async acceptPlace(_, uuid) {
    try {
      await this.$http.post('/api/v2/rent_transaction/accept_place', { uuid });
      // eslint-disable-next-line no-empty
    } catch (e) {
      console.log('ERROR rentTransaction/acceptPlace: ', e);
      throw e;
    }
  },

  async initiateOfflinePay(_, uuid) {
    try {
      await this.$http.post('/api/v2/rent_transaction/initiate_offline_pay', { uuid });
      // eslint-disable-next-line no-empty
    } catch (e) {
      console.log('ERROR rentTransaction/initiate_offline_pay: ', e);
      throw e;
    }
  },
};
