<template>
  <section class="breadcrumb--section" v-if="$mq !== 'xs' && breadcrumbs && breadcrumbs.length > 0">
    <div class="container">
      <v-breadcrumbs :items="breadcrumbs">
        <template #divider v-if="breadcrumbs.length > 1">
          <div class="arrow">
            <i class="ion-ios-arrow-right"></i>
          </div>
        </template>
        <template #divider v-else>
          <div class="arrow">
            <i class="ion-ios-arrow-left"></i>
          </div>
        </template>
      </v-breadcrumbs>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      default: [],
    },
  },
  data: () => ({
    links: [
      {
        title: 'Kembali',
        url: '/',
      },
    ],
  }),
};
</script>
