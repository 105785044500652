const requireContext = require.context('./detail', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  isLoading: false,
  type: null,
  uuid: null,
  listing: null,
  allowedActions: [],
  bookingInfo: null,
  tourInfo: null,
  customAgreementData: null,
  timeline: [],
  orderNumber: null,
  guideline: null,
});

export const getters = {};

export const mutations = {
  SET_GUIDELINE(state, payload) {
    state.guideline = payload;
  },
  SET_TOUR_INFO(state, payload) {
    state.tourInfo = payload;
  },
  SET_CUSTOM_AGREEMENT_DATA(state, payload) {
    state.customAgreementData = payload;
  },
  SET_TYPE(state, payload) {
    state.type = payload;
  },
  SET_LISTING(state, payload) {
    state.listing = payload;
  },
  SET_ALLOWED_ACTIONS(state, payload) {
    state.allowedActions = payload;
  },
  SET_ORDER_NUMBER(state, payload) {
    state.orderNumber = payload;
  },
  SET_BOOKING_INFO(state, payload) {
    state.bookingInfo = payload;
  },
  SET_TIMELINE(state, payload) {
    state.timeline = payload;
  },
  SET_UUID(state, payload) {
    state.uuid = payload;
  },
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload;
  },
  RESTORE_INITIAL_STATE(state) {
    state.type = null;
    state.uuid = null;
    state.tourInfo = null;
    state.customAgreementData = null;
    state.guideline = null;
    state.isLoading = false;
    state.listing = null;
    state.allowedActions = [];
    state.bookingInfo = null;
    state.orderNumberr = null;
    state.timeline = [];
  },
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async checkAccessAllowed({ commit, state }, { type, uuid }) {
    try {
      // commit('SET_IS_LOADING', true);
      const { data } = await this.$http.get('api/v2/mybooking/access_detail_allowed', {
        params: {
          type,
          uuid,
        },
      });
      return data.data;
    } finally {
      // commit('SET_IS_LOADING', false);
    }
  },
  async getData({ commit, state }) {
    try {
      commit('SET_IS_LOADING', true);
      const { data } = await this.$http.get('api/v2/mybooking/get_detail', {
        params: {
          type: state.type,
          uuid: state.uuid,
        },
      });
      commit('v2/rentTransaction/initiateTour/SET_RENT_TRANSACTION_UUID', state.uuid, {
        root: true,
      });
      // console.log('aakakakaka',state.uuid);
      commit('v2/rentTransaction/initiateTour/SET_TOUR_PROG_UUID', state.uuid, { root: true });

      commit('SET_LISTING', data.data.listing);
      commit('SET_ALLOWED_ACTIONS', data.data.allowed_actions);
      commit('SET_BOOKING_INFO', data.data.booking_info);
      commit('SET_TIMELINE', data.data.timeline);
      commit('SET_GUIDELINE', data.data.guideline);
      commit('SET_TOUR_INFO', data.data.tour_info);
      commit('SET_CUSTOM_AGREEMENT_DATA', data.data.custom_agreement_data);
      commit('SET_ORDER_NUMBER', data.data.order_number ? data.data.order_number : null);
      console.log('RESPONSE DETAIL:', data);
      console.log('OLD UUID:', state.uuid);
      console.log('OLD TYPE:', state.type);
      if (state.uuid !== data.data.uuid) {
        commit('SET_TYPE', data.data.type);
        commit('SET_UUID', data.data.uuid);
        commit('v2/rentTransaction/initiateTour/SET_TOUR_PROG_UUID', state.uuid, { root: true });
        return {
          changeUuid: true,
        };
      }
      return null;
      // return data.data;
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async acceptCustomAgreement({ commit }, uuid) {
    try {
      commit('SET_IS_LOADING', true);
      const { data } = await this.$http.post('api/v2/custom_agreement/accept', {
        uuid: uuid,
      });
      return data;
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async rejectCustomAgreement({ commit }, uuid) {
    try {
      commit('SET_IS_LOADING', true);
      const { data } = await this.$http.post('api/v2/custom_agreement/reject', {
        uuid: uuid,
      });
      return data;
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
};
