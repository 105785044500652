<!--
  This component is responsible of displaying a nice error page in case the errorHandler plugin have caught an error
-->
<template>
  <default-layout>
    <section class="error--page py-5">
      <div class="error-container">
        <div class="text-center mb-5">
          <div class="title--text">{{ $t('errorPage.generalErrorTitle') }}</div>
          <div class="subtitle">{{ $t('errorPage.generalErrorSubtitleFirst') }}</div>
          <div class="subtitle">
            {{ $t('errorPage.generalErrorSubtitleSecond') }}
            <router-link to="/contact-us">{{ $t('errorPage.errorContact') }}</router-link>
          </div>
        </div>
        <img
          width="300"
          src="https://s3-ap-southeast-1.amazonaws.com/rentfix/static/img/icon/error.svg"
          class="error-image"
        />
      </div>
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
export default {
  mixins: [DefaultHeadMixin],
  components: { DefaultLayout },
};
</script>

<style lang="scss" scoped></style>
