export const state = () => ({
  districts: [],
  selectedDistrict: {},
});

export const getters = {};

export const mutations = {
  SET_DISTRICTS(state, payload) {
    state.districts = payload;
  },
  SET_SELECTED_DISTRICT(state, payload) {
    state.selectedDistrict = payload;
  },
  RESTORE_DEFAULT_VALUE(state) {
    state.districts = [];
    state.selectedDistrict = {};
  },
};

export const actions = {
  async getDirectoryData({ commit }, propertyTypeName) {
    try {
      const response = await this.$http.get('/api/v2/directory/get_data', {
        params: {
          property_type: propertyTypeName,
        },
      });
      commit('SET_DISTRICTS', response.data && response.data.data ? response.data.data.cities : []);
      return response.data;
    } catch (e) {
      throw e;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getDirectoryListing({ commit }, { listingType, propertyType }) {
    try {
      const response = await this.$http.get('/api/v2/search', {
        params: {
          listing_type: listingType,
          property_type_id: propertyType ? propertyType.id : null,
          paginate: 4,
        },
      });

      return response.data.data.data;
    } catch (e) {
      throw e;
    }
  },
  setSelectedDistrictById({ commit, state }, payload) {
    let selectedDistrict = state.districts.find(function (item) {
      return item.id === payload;
    });
    commit('SET_SELECTED_DISTRICT', selectedDistrict);

    return null;
  },
};
