<template>
  <v-app>
    <sidebar-layout></sidebar-layout>
    <header-layout :class="homepage ? 'header--homepage' : ''" v-if="!useMobileParam" />
    <div class="header--height" v-if="!useMobileParam"></div>
    <!--    <div :style="{ height: !useMobileParam ? headerHeight + 'px' : 0 }"></div>-->
    <breadcrumb :breadcrumbs="breadcrumbs" />
    <slot />
    <footer-layout v-if="useFooter && $route.query.mobile !== 'true'" />
    <modal-login></modal-login>
    <modal-register></modal-register>
    <modal-forget-pass></modal-forget-pass>
    <modal-forget-pass-confirm></modal-forget-pass-confirm>
    <modal-covid-protocol></modal-covid-protocol>
    <modal-accept-survey-confirm></modal-accept-survey-confirm>
    <modal-choose-date></modal-choose-date>
    <confirm-service-fee-tc-modal />
    <input-bank-account-modal />
    <modal-success />

    <client-only>
      <validation-handler />
    </client-only>
  </v-app>
</template>

<script>
import Breadcrumb from '@/components/layouts/partials/breadcrumb';
const ValidationHandler = () => import('@/components/utils/validation-handler');
const HeaderLayout = () => import('@/components/layouts/header-layout');
const FooterLayout = () => import('@/components/layouts/footer-layout');
const SidebarLayout = () => import('@/components/layouts/sidebar');
const ModalLogin = () => import('@/components/auth/modals/modal-login');
const ModalRegister = () => import('@/components/auth/modals/modal-register');
const ModalForgetPass = () => import('@/components/auth/modals/modal-forget-pass');
const ModalForgetPassConfirm = () => import('@/components/auth/modals/modal-forget-pass-confirm');
const ModalCovidProtocol = () => import('@/components/listing-detail/modals/modal-covid-protocol');
const ModalAcceptSurveyConfirm = () => import('@/components/booking/modals/accept-survey-confirm');
const ModalChooseDate = () => import('@/components/booking/modals/choose-date');
const ConfirmServiceFeeTcModal = () =>
  import('@/components/profile/modals/confirm-service-fee-tc-modal.vue');
const InputBankAccountModal = () =>
  import('@/components/bank-account/modals/input-bank-account-modal');
const ModalSuccess = () => import('@/components/listing-form/modals/modal-success.vue');
import tawkto from '@/mixins/tawkto.js';
import { mapState } from 'vuex';
export default {
  head() {
    return this.head;
  },
  created() {},
  mixins: [tawkto],
  components: {
    Breadcrumb,
    HeaderLayout,
    ValidationHandler,
    FooterLayout,
    SidebarLayout,
    ModalLogin,
    ModalRegister,
    ModalForgetPass,
    ModalForgetPassConfirm,
    ModalCovidProtocol,
    ModalAcceptSurveyConfirm,
    ModalChooseDate,
    ConfirmServiceFeeTcModal,
    InputBankAccountModal,
    ModalSuccess,
  },
  computed: {
    useMobileParam() {
      return this.$route.query.mobile === 'true';
    },
    headerHeight() {
      return this.$store.getters['global/getHeaderHeight']();
    },
    ...mapState({
      head: (state) => state.head.content,
    }),
  },
  props: {
    homepage: {
      type: Boolean,
      default: false,
    },
    useFooter: {
      type: Boolean,
      default: true,
    },
    breadcrumbs: { type: Array, default: () => null },
  },
};
</script>
