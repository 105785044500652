export const state = () => ({
  propertyList: [],
  propertyTypeList: [],
  propertyTypeCategoryList: [],
  propertyTypeCategoryGroupList: [],
  propertyOwnershipTypeList: [],
  officialPartnerAreas: [],
  addressLine1: '',
  addressLine2: '',
  aboutProperty: '',
  latitude: '',
  longitude: '',
  amenities: [''],
  propertyUuid: null,
  propertyName: '',
  selectedCountry: null,

  loadPropertyDataFromList: false,
  loadingMap: false,
  isTouched: false,
  defaultAddState: {},
});

export const getters = {
  sortedPropertyCategoryList(state) {
    let temp = JSON.parse(JSON.stringify(state.propertyTypeCategoryList));
    return temp.sort((a, b) => {
      return a.sequence_number - b.sequence_number;
    });
  },
  allowedOfficialPartnerAreas(state, geters, rootState) {
    return state.officialPartnerAreas.filter((area) => {
      return area.listing_type === rootState.v2.listingForm.listingType;
    });
  },
  getPropertyTypeList(state) {
    let allData = {};
    state.propertyTypeList.forEach(function (element) {
      if (!allData[element.propertyTypeCategoryId]) {
        allData[element.propertyTypeCategoryId] = [];
        allData[element.propertyTypeCategoryId].push(element);
      } else {
        allData[element.propertyTypeCategoryId].push(element);
      }
    });
    return allData;
  },
  formData(state, getters) {
    const selectedPOType = state.propertyOwnershipTypeList.find(function (poType_) {
      return poType_.selected === true;
    });
    let formData = {
      country_id: state.selectedCountry,
      property_uuid: getters.propertyUuidForm,
      property_name: state.propertyName,
      property_type_id: getters.selectedPropertyType,
      official_partner_area_uuid: getters.selectedOfficialPartnerArea,
      property_ownership_type_id: !selectedPOType ? null : selectedPOType.id,
      address_line_1: state.addressLine1,
      address_line_2: state.addressLine2,
      about_property: state.aboutProperty,
      latitude: state.latitude,
      longitude: state.longitude,
      amenities: JSON.parse(JSON.stringify(state.amenities)),
    };
    if (selectedPOType) {
      if (formData.property_ownership_type_id != 1) {
        formData['property_owner_name[' + selectedPOType.id + ']'] = selectedPOType.ownerName;
      }
    }
    return formData;
  },
  propertyTypeList(state) {
    let allData = {};
    state.propertyTypeList.forEach(function (element) {
      if (!allData[element.propertyTypeCategoryId]) {
        allData[element.propertyTypeCategoryId] = [];
        allData[element.propertyTypeCategoryId].push(element);
      } else {
        allData[element.propertyTypeCategoryId].push(element);
      }
    });
    return allData;
  },
  selectedOfficialPartnerArea(state) {
    const poType = state.officialPartnerAreas.find(function (poType_) {
      return poType_.selected === true;
    });
    if (poType) {
      return poType.uuid;
    }
    return null;
  },
  selectedOfficialPartnerAreaObject(state) {
    const poType = state.officialPartnerAreas.find(function (poType_) {
      return poType_.selected === true;
    });
    if (poType) {
      return poType;
    }
    return null;
  },
  selectedPropertyOwnershipType(state) {
    const poType = state.propertyOwnershipTypeList.find(function (poType_) {
      return poType_.selected === true;
    });
    if (poType) {
      return poType.id;
    }
    return null;
  },
  selectedPropertyOwnershipTypeObject(state) {
    const poType = state.propertyOwnershipTypeList.find(function (poType_) {
      return poType_.selected === true;
    });
    if (poType) {
      return poType;
    }
    return null;
  },
  selectedPropertyTypeCategoryGroup(state) {
    if (state.propertyTypeCategoryGroupList) {
      const poType = state.propertyTypeCategoryGroupList.find(function (poType_) {
        return poType_.selected === true;
      });
      if (poType !== undefined) {
        return poType;
      }
    }

    return null;
  },
  selectedPropertyType(state) {
    const poType = state.propertyTypeList.find(function (poType_) {
      return poType_.selected === true;
    });
    if (poType !== undefined) {
      return poType.id;
    }
    return null;
  },
  selectedPropertyTypeName(state) {
    const poType = state.propertyTypeList.find(function (poType_) {
      return poType_.selected === true;
    });
    if (poType !== undefined) {
      return poType.name;
    }
    return null;
  },
  selectedPropertyTypeCategory(state) {
    const poType = state.propertyTypeList.find(function (poType_) {
      return poType_.selected === true;
    });
    if (poType !== undefined) {
      return poType.propertyTypeCategoryId;
    }
    return null;
  },
  propertyListOptions(state) {
    return state.propertyList.map(function (property) {
      return property.property_name;
    });
  },
  selectedPropertyName(state) {
    let selectedPropertyName = state.propertyList.find(function (property) {
      return property.selected;
    });
    if (selectedPropertyName) {
      return selectedPropertyName.property_name;
    }
    return null;
  },
  selectedPropertyUuid(state) {
    if (Object.keys(state.propertyList).length === 0) {
      return state.propertyUuid;
    } else {
      let selectedPropertyName = state.propertyList.find(function (property) {
        return property.selected;
      });
      if (selectedPropertyName) {
        return selectedPropertyName.uuid;
      }
      return null;
    }
  },
  propertyUuidForm(state, getters) {
    if (getters.selectedPropertyUuid) {
      return getters.selectedPropertyUuid;
    } else {
      return state.propertyUuid;
    }
  },
};

export const mutations = {
  SET_LOADING_MAP(state, payload) {
    state.loadingMap = payload;
  },
  SET_IS_TOUCHED(state, payload) {
    state.isTouched = payload;
  },
  SET_LOAD_PROPERTY_DATA(state, payload) {
    state.loadPropertyDataFromList = payload;
  },
  RESTORE_INITIAL_STATE(state) {
    state.propertyList = [];
    state.propertyTypeList = [];
    state.propertyTypeCategoryList = [];
    state.propertyTypeCategoryGroupList = [];
    state.propertyOwnershipTypeList = [];
    state.officialPartnerAreas = [];
    state.addressLine1 = '';
    state.addressLine2 = '';
    state.aboutProperty = '';
    state.latitude = '';
    state.longitude = '';
    state.amenities = [''];
    state.propertyUuid = null;
    state.propertyName = '';
    state.selectedCountry = null;
    state.defaultAddState = {};
    state.isTouched = false;
  },
  SET_SELECTED_COUNTRY(state, payload) {
    state.selectedCountry = payload;
  },
  SET_PROPERTY_TYPE_CATEGORY_LIST(state, payload) {
    state.propertyTypeCategoryList = payload;
  },
  SET_PROPERTY_TYPE_CATEGORY_GROUP_LIST(state, payload) {
    payload.forEach(function (element, index_, arr_) {
      arr_[index_].selected = false;
    });
    state.propertyTypeCategoryGroupList = payload;
  },
  SET_SELECTED_PROPERTY_TYPE_CATEGORY_GROUP_LIST(state, id) {
    state.propertyTypeCategoryGroupList.forEach(function (property, index_, arr_) {
      if (property.id == id) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
  },
  SET_DEFAULT_ADD_STATE(state) {
    const currentState = JSON.parse(
      JSON.stringify({
        propertyList: state.propertyList,
        propertyTypeList: state.propertyTypeList,
        propertyOwnershipTypeList: state.propertyOwnershipTypeList,
        officialPartnerAreas: state.officialPartnerAreas,
        addressLine1: state.addressLine1,
        addressLine2: state.addressLine2,
        aboutProperty: state.aboutProperty,
        // latitude: state.latitude,
        // longitude: state.longitude,
        amenities: state.amenities,
        propertyUuid: state.propertyUuid,
        propertyName: state.propertyName,
      }),
    );
    state.defaultAddState = currentState;
  },
  RESTORE_DEFAULT_ADD_STATE(state) {
    const defaultVal = JSON.parse(JSON.stringify(state.defaultAddState));

    // state.propertyList=defaultVal.propertyList
    state.propertyTypeList = defaultVal.propertyTypeList;
    state.propertyOwnershipTypeList = defaultVal.propertyOwnershipTypeList;
    state.officialPartnerAreas = defaultVal.officialPartnerAreas;
    state.addressLine1 = defaultVal.addressLine1;
    state.addressLine2 = defaultVal.addressLine2;
    state.aboutProperty = defaultVal.aboutProperty;
    // state.latitude=defaultVal.latitude
    // state.longitude=defaultVal.longitude
    state.amenities = defaultVal.amenities;
    state.propertyUuid = defaultVal.propertyUuid;
    state.propertyName = defaultVal.propertyName;
  },
  SET_SELECTED_PROPERTY_NAME(state, propertyName) {
    state.propertyList.forEach(function (property, index_, arr_) {
      if (property.property_name == propertyName) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
  },
  SET_SELECTED_PROPERTY_NAME_BY_UUID(state, propertyUuid) {
    state.propertyList.forEach(function (property, index_, arr_) {
      arr_[index_].selected = property.uuid === propertyUuid;
    });
  },
  RESET_SELECTED_PROPERTY_NAME(state) {
    state.propertyList.forEach(function (property, index_, arr_) {
      arr_[index_].selected = false;
    });
  },
  SET_PROPERTY_LIST(state, payload) {
    payload.forEach(function (element, index_, arr_) {
      arr_[index_].selected = false;
    });
    state.propertyList = payload;
  },

  SET_PROPERTY_NAME(state, newPropName) {
    state.propertyName = newPropName;
  },

  ADD_AMENITY(state) {
    if (state.amenities.length < 5) {
      state.amenities.push('');
    }
  },
  SET_AMENITIES(state, amenities) {
    let temp = [];
    amenities.forEach(function (element) {
      if (temp.length < 5) {
        temp.push(element);
      }
    });
    state.amenities = temp;
  },
  REMOVE_AMENITY(state, index) {
    if (state.amenities.length > 1) {
      state.amenities.splice(index, 1);
    }
  },
  INPUT_AMENITY(state, payload) {
    state.amenities.splice(payload.index, 1, payload.input);
  },
  CHANGE_ADDRESSLINE1(state, addrl1) {
    state.addressLine1 = addrl1;
  },
  CHANGE_ADDRESSLINE2(state, addrl2) {
    state.addressLine2 = addrl2;
  },
  CHANGE_ABOUTPROPERTY(state, aboutProperty) {
    state.aboutProperty = aboutProperty;
  },
  CHANGE_LATITUDE(state, lat) {
    state.latitude = Number(lat);
  },
  CHANGE_LONGITUDE(state, lng) {
    state.longitude = Number(lng);
  },
  CHANGE_PROP_OWNERSHIP_NAME(state, payload) {
    if (payload.propertyOwnerTypeId != 1) {
      const poType = state.propertyOwnershipTypeList.find(function (poType_) {
        return poType_.id === payload.propertyOwnerTypeId;
      });
      poType.ownerName = payload.propertyOwnerTypeName;
    }
  },
  SET_SELECTED_PROPERTY_OWNERSHIP_TYPE(state, propertyOwnershipId) {
    state.propertyOwnershipTypeList.forEach(function (element, index_, arr_) {
      if (element.id === propertyOwnershipId) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
  },
  SET_SELECTED_OFFICIAL_PARTNER_AREA(state, officialPartnerAreaUuid) {
    state.officialPartnerAreas.forEach(function (element, index_, arr_) {
      if (element.uuid === officialPartnerAreaUuid) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
  },
  SET_SELECTED_PROPERTY_OWNERSHIP_TYPE_OBJECT(state, propertyOwnership) {
    state.propertyOwnershipTypeList.forEach(function (element, index_, arr_) {
      if (element.id === propertyOwnership.id) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
      }
    });
  },

  SET_PROPERTY_OWNERSHIP_TYPE_LIST(state, payload) {
    payload.forEach(function (element, index_, arr_) {
      if (element.id === 1) {
        arr_[index_].selected = true;
      } else {
        arr_[index_].selected = false;
        arr_[index_].ownerName = '';
      }
    });
    state.propertyOwnershipTypeList = payload;
  },
  SET_OFFICAL_PARTNER_AREAS(state, payload) {
    payload.forEach(function (element, index_, arr_) {
      arr_[index_].selected = false;
    });
    state.officialPartnerAreas = payload;
  },

  SET_PROPERTY_TYPE_LIST(state, payload) {
    payload.forEach(function (propertyType, index, arr) {
      arr[index].selected = false;
    });
    state.propertyTypeList = payload;
  },
  SET_ACTIVE_PROPERTY_TYPE(state, propertyTypeId) {
    state.propertyTypeList.forEach(function (propertyType, index, arr) {
      arr[index].selected = propertyType.id === propertyTypeId;
    });
  },
  SET_PROPERTY_UUID(state, propertyUuid) {
    state.propertyUuid = propertyUuid;
  },
};

export const actions = {
  applyEditData({ commit, dispatch }, { data }) {
    dispatch('v2/listingForm/sect1/regionalListingForm/setRegionalData', data.regional_data, {
      root: true,
    });
    commit('CHANGE_ADDRESSLINE1', data.address_line_1);
    commit('CHANGE_ADDRESSLINE2', data.address_line_2);

    commit('SET_PROPERTY_UUID', data.property_uuid);
    commit('SET_PROPERTY_NAME', data.property_name);
    commit('SET_ACTIVE_PROPERTY_TYPE', data.property_type_id);
    commit('SET_AMENITIES', data.amenities);
    commit('CHANGE_LATITUDE', data.latitude);
    commit('CHANGE_LONGITUDE', data.longitude);
    commit('SET_SELECTED_OFFICIAL_PARTNER_AREA', data.official_partner_area_uuid);
    commit('SET_SELECTED_PROPERTY_OWNERSHIP_TYPE', data.property_ownership_type_id);
    const propOwnerNamePayload = {
      propertyOwnerTypeId: data.property_ownership_type_id,
      propertyOwnerTypeName: data.owner_name,
    };
    commit('CHANGE_PROP_OWNERSHIP_NAME', propOwnerNamePayload);
    commit('CHANGE_ABOUTPROPERTY', data.about_property);
    // commit('SET_SELECTED_COUNTRY',payload.countryId);
  },
  getPropertyDataByUuid({ commit, getters, dispatch }) {
    commit('SET_LOAD_PROPERTY_DATA', true);
    const propertyUuid = getters.selectedPropertyUuid;
    return this.$http
      .get('/api/v1/mylisting/getPropertyDataByUuid/' + propertyUuid)
      .then((response) => {
        const data = response.data;
        commit('SET_AMENITIES', data.amenities);
        commit('SET_PROPERTY_NAME', data.property.property_name);
        commit('SET_ACTIVE_PROPERTY_TYPE', data.property.property_type_id);
        commit('RESET_SELECTED_PROPERTY_NAME');
        commit('SET_SELECTED_PROPERTY_NAME_BY_UUID', propertyUuid);
        commit('SET_SELECTED_PROPERTY_OWNERSHIP_TYPE', data.property.propertyOwnerShipTypeId);
        const propOwnerNamePayload = {
          propertyOwnerTypeId: data.property.propertyOwnerShipTypeId,
          propertyOwnerTypeName: data.property.property_owner.full_name,
        };
        commit('CHANGE_PROP_OWNERSHIP_NAME', propOwnerNamePayload);
        dispatch('v2/listingForm/sect1/regionalListingForm/setRegionalData', data.regionalData, {
          root: true,
        });

        commit('CHANGE_ADDRESSLINE1', data.property.address_line_1);
        commit('CHANGE_ADDRESSLINE2', data.property.address_line_2);
        // commit('CHANGE_ABOUTPROPERTY', data.property.about_property);
        commit('CHANGE_LATITUDE', data.property.latitude);
        commit('CHANGE_LONGITUDE', data.property.longitude);

        return true;
      })
      .catch(function () {
        return false;
      })
      .finally(() => {
        commit('SET_LOAD_PROPERTY_DATA', false);
      });
  },

  applyBaseData({ commit }, { form, data }) {
    if (form === 'add') {
      commit('SET_PROPERTY_TYPE_CATEGORY_GROUP_LIST', data.propertyTypeCategoryGroup);
      commit('SET_PROPERTY_TYPE_CATEGORY_LIST', data.propertyTypeCategory);
      commit('SET_PROPERTY_LIST', data.propertyNames);
    }
    commit('SET_PROPERTY_TYPE_LIST', data.propertyTypes);
    commit('SET_PROPERTY_OWNERSHIP_TYPE_LIST', data.propertyOwnershipTypes);
    commit('SET_OFFICAL_PARTNER_AREAS', data.official_partner_areas);
  },
};
