export const state = () => ({
  document: null,
  isLoading: true,
});

export const mutations = {
  SET_DOCUMENT(state, payload) {
    if (payload.rent_price) {
      payload.listing_type = 'R';
    } else if (payload.sell_price) {
      payload.listing_type = 'S';
    }
    state.document = payload;
  },
  SET_LOADING_TRUE(state) {
    state.isLoading = true;
  },
  SET_LOADING_FALSE(state) {
    state.isLoading = false;
  },
};

export const actions = {
  async getInvoice({ commit }, { orderNumber, token, feKey }) {
    commit('SET_LOADING_TRUE');
    let options = {
      params: {
        order_number: orderNumber,
      },
    };
    let resultData;
    if (!feKey) {
      if (token) {
        options.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      let { data } = await this.$http.get('/api/v2/transaction_doc/invoice', options);
      resultData = data;
    } else {
      options.headers = {
        'fe-key': feKey,
      };
      let { data } = await this.$http.get('/api/v2/transaction_doc/invoice_inhs', options);
      resultData = data;
    }
    commit('SET_DOCUMENT', resultData.data);

    commit('SET_LOADING_FALSE');
  },
};
