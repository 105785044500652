export const state = () => ({
  listingType: 'R',
  isFetchingListing: true,
});

export const getters = {};

export const mutations = {
  SET_LISTING_TYPE(state, payload) {
    state.listingType = payload;
  },
  SET_LOADING(state, payload) {
    state.isFetchingListing = payload;
  },
  RESTORE_INITIAL_STATE(state) {
    state.isFetchingListing = true;
    state.listingType = 'R';
  },
};

export const actions = {
  async changeListingType({ commit, dispatch, state }) {
    try {
      commit('SET_LOADING', true);
      await dispatch('v2/listing/getMyShortlists', state.listingType, { root: true }); // -> 'someOtherAction'
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
