export const state = () => ({
  loading: true,
});

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
};

export const actions = {
  async visitListing({ commit }, { uuid }) {
    try {
      commit('SET_LOADING', true);
      const result = await this.$http.post('/api/v2/visit/listing', {
        listing_uuid: uuid,
      });
      console.log('RESULT POST VISIT LISTING: ', result.data);
    } catch (e) {
      console.log('ERROR POST VISIT LISTING: ', e);
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
