<template>
  <div id="app">
    <script v-html="jsonld" type="application/ld+json"></script>
    <client-only> <vue-progress-bar /> </client-only>
    <not-found
      v-if="$errorHandler && $errorHandler.statusCode >= 404 && $errorHandler.statusCode < 500"
    ></not-found>
    <error-page v-else-if="$errorHandler && $errorHandler.error" />
    <router-view v-else />
  </div>
</template>

<script>
import { headConfig } from '@/config/head.js';
import NotFound from '@/components/404';
import ErrorPage from './components/ErrorPage.vue';

export default {
  head: headConfig,
  components: { NotFound, ErrorPage },
  computed: {
    jsonld() {
      let baseUrl = this.$store.state.global.baseUrl;
      return {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        '@id': baseUrl + '/#website',
        description: this.$store.state.head.defaultDescription,
        inLanguage: 'id-ID',
        name: 'Rentfix',
        url: baseUrl + '/',
        potentialAction: {
          '@type': 'SearchAction',
          'query-input': 'required name=search_term_string',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: baseUrl + '/sewa/search/semua/{search_term_string}/',
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
