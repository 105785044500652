export const state = () => ({
  propertyTypes: [],
  rentTypes: [],
  rentPeriods: [],
  propertyType: '',
  rentType: {},
  listingPartner: [],
  propertyPartner: [],
  projectPartners: [],
  cities: [],
});

export const getters = {
  propertyTypesSearch(state) {
    let propertyTypes = state.propertyTypes;
    if (!propertyTypes.find((propertyType) => propertyType.id === null)) {
      propertyTypes.unshift({
        id: null,
        name: 'general.all',
      });
    }
    return propertyTypes;
  },
};

export const mutations = {
  set_property_types(state, payload) {
    state.propertyTypes = payload;
  },
  set_property_type(state, payload) {
    state.propertyType = payload;
  },
  set_rent_types(state, payload) {
    state.rentTypes = payload;
  },
  set_rent_type(state, payload) {
    state.rentType = payload;
  },
  set_rent_periods(state, payload) {
    state.rentPeriods = payload;
  },
  set_listing_partner(state, payload) {
    state.listingPartner = payload;
  },
  set_property_partner(state, payload) {
    state.propertyPartner = payload;
  },
  SET_PROJECT_PARTNERS(state, payload) {
    state.projectPartners = payload;
  },
  SET_PROPERTY_TYPES_HEADER_RESIDENTIAL(state, payload) {
    state.propertyTypesHeaderResidential = payload;
  },
  SET_PROPERTY_TYPES_HEADER_COMMERCIAL(state, payload) {
    state.propertyTypesHeaderCommercial = payload;
  },
  SET_CITIES(state, payload) {
    state.cities = payload;
  },
};

export const actions = {
  async getPropertyTypes({ commit, rootState }, { group: group }) {
    await this.$http
      .get('/api/v2/master/property-types', {
        params: {
          group: group,
          listing_type: rootState.v2.search.listingType,
        },
      })
      .then((response) => {
        commit('set_property_types', response.data.data);
      });
  },

  // eslint-disable-next-line no-unused-vars
  async getPropertyTypesByCategory({ commit, rootState }, categoryId) {
    await this.$http
      .get('/api/v2/master/property-types', {
        params: {
          category_id: categoryId && !isNaN(categoryId) ? categoryId : null,
          listing_type: rootState.v2.search.listingType,
        },
      })
      .then((response) => {
        commit('set_property_types', response.data.data);
      });
  },

  // eslint-disable-next-line no-unused-vars
  async getPropertyTypesBySearchType({ commit, rootState }) {
    await this.$http
      .get('/api/v2/master/property-types', {
        params: {
          listing_type: rootState.v2.search.listingType,
          is_official_partner_area: rootState.v2.search.isOfficialPartner,
        },
      })
      .then((response) => {
        commit('set_property_types', response.data.data);
      });
  },

  // eslint-disable-next-line no-unused-vars
  async getPropertyTypesHeader({ commit }, { category_id: categoryId, listing_type: listingType }) {
    let response = await this.$http.get('/api/v2/master/property-types', {
      params: {
        category_id: categoryId,
        listing_type: listingType,
      },
    });
    return response.data.data;
  },

  async getPropertyTypeById({ commit }, id) {
    let response = await this.$http.get('/api/v2/master/' + id + '/property-type');
    commit('set_property_type', response.data.data);
  },
  // eslint-disable-next-line
    async getPropertyTypeByName({ commit }, propertyTypeName) {
    let response = await this.$http.get('/api/v2/master/property-type/' + propertyTypeName);

    return response.data.data;
  },
  async getRentTypes({ commit }) {
    await this.$http.get('/api/v2/master/rent-types').then((result) => {
      commit('set_rent_types', result.data.data);
    });
  },

  async getRentTypesByCategory({ commit }, categoryId) {
    await this.$http
      .get('/api/v2/master/rent-types', {
        params: {
          category_id: categoryId && !isNaN(categoryId) ? categoryId : null,
        },
      })
      .then((response) => {
        commit('set_rent_types', response.data.data);
      });
  },

  async getRentTypeById({ commit }, id) {
    await this.$http.get('/api/v2/master/' + id + '/rent-type').then((response) => {
      commit('set_rent_type', response.data.data);
    });
  },
  async getRentPeriods({ commit }) {
    await this.$http.get('/api/v2/master/rent-periods').then((result) => {
      commit('set_rent_periods', result.data.data);
    });
  },

  async getRentPeriodsByCategory({ commit }, categoryId) {
    await this.$http
      .get('/api/v2/master/rent-periods', {
        params: {
          category_id: categoryId && !isNaN(categoryId) ? categoryId : null,
        },
      })
      .then((response) => {
        commit('set_rent_periods', response.data.data);
      });
  },
  // eslint-disable-next-line no-empty-pattern
  async getCitiesByProvinceId({ commit }, province_id) {
    let response = await this.$http.get('/api/v2/regional/get-cities-by-province', {
      params: {
        province_id: province_id,
      },
    });

    const data = response.data.data;
    commit('SET_CITIES', data);
    return data;
  },
  // eslint-disable-next-line no-empty-pattern
  async getDistrictsByCityId({}, city_id) {
    let response = await this.$http.get('/api/v2/regional/get-districts-by-city', {
      params: {
        city_id: city_id,
      },
    });

    return response.data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async getVillagesByDistrictId({}, district_id) {
    let response = await this.$http.get('/api/v2/regional/get-villages-by-district', {
      params: {
        district_id: district_id,
      },
    });

    return response.data.data;
  },
  async getListingPartner({ commit }) {
    let response = await this.$http.get('/api/v2/master/listing-partner');

    commit('set_listing_partner', response.data.data);
  },
  async getPropertyPartner({ commit }) {
    let response = await this.$http.get('/api/v2/master/property-partner');

    commit('set_property_partner', response.data.data);
  },
  async getProjectPartners({ commit }) {
    let response = await this.$http.get('/api/v2/search/project-partners', {
      params: {
        paginate: 30,
      },
    });

    commit('SET_PROJECT_PARTNERS', response.data.data.data);
  },
};
