export const state = () => ({
  banners: null,
  bannerDetail: null,
  loading: true,
});

export const mutations = {
  SET_BANNER(state, payload) {
    state.banners = payload;
  },
  SET_BANNER_DETAIL(state, payload) {
    state.bannerDetail = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
};

export const actions = {
  async getBanner({ commit }) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.get('/api/v2/banner/get_banner_list');
      // console.log('GET BANNER DATA', data.data);
      commit('SET_BANNER', data.data);
    } catch (e) {
      console.log('ERROR GET BANNER LIST: ', e);
      commit('SET_BANNER', null);
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getBannerDetail({ commit }, uuid) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.get('/api/v2/banner/get_detail', {
        params: {
          banner_uuid: uuid,
        },
      });
      commit('SET_BANNER_DETAIL', data.data);
    } catch (e) {
      console.log('ERROR GET BANNER DETAIL: ', e);
      commit('SET_BANNER_DETAIL', null);
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
