export const state = () => ({
  propertyPartner: null,
  loadingPartnerDetail: false,
});

export const mutations = {
  SET_PROPERTY(state, payload) {
    state.propertyPartner = payload;
  },
  SET_LOADING_PARTNER_DETAIL(state, payload) {
    state.loadingPartnerDetail = payload;
  },
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async getPropertyPartnerDetail({ commit, state }, params) {
    try {
      commit('SET_LOADING_PARTNER_DETAIL', true);
      const { data } = await this.$http.get('/api/v2/property_partner/get_data', {
        params: {
          url: params,
        },
      });
      commit('SET_PROPERTY', {
        brand: {
          idName: data.data.url,
          name: data.data.partner_name,
          address: data.data.partner_address,
          image: data.data.partner_picture_url,
        },
        contact: {
          developer: {
            web: data.data.developer_web_url,
            image: data.data.developer_picture_url,
          },
          name: data.data.developer_name,
          call:
            'https://api.whatsapp.com/send?phone=' +
            encodeURI(data.data.developer_wa) +
            '&text=Hallo%20Saya%20Mau%20info%20properti%20' +
            encodeURI(data.data.developer_name) +
            '.',
          number: data.data.developer_phone,
        },
        gallery: {
          primaryPhoto: data.data.primary_photo,
          listPhotos: data.data.list_photos,
          panoEmbedUrl: data.data.pano_embed_url,
        },
        overview: {
          price: data.data.project_price_range,
          type:
            data.data.property_partner_units && data.data.property_partner_units.length > 0
              ? data.data.property_partner_units[0].property_type
              : null,
          developer:
            '<a href="' +
            encodeURI(data.data.developer_web_url) +
            '" target="_blank">' +
            data.data.developer_name +
            '</a>',
        },
        desc: data.data.project_description,
        detail: {
          type:
            data.data.property_partner_units && data.data.property_partner_units.length > 0
              ? data.data.property_partner_units[0].property_type
              : null,
          start: data.data.dev_start_date,
          finish: data.data.dev_end_date,
          sizeArea: data.data.project_area_size,
          typeCount: data.data.property_partner_units.length,
          totalFloor: data.data.total_floor,
        },
        unit: {
          type: data.data.property_partner_units,
          brosur: data.data.brochure_url,
          call:
            'https://api.whatsapp.com/send?phone=' +
            encodeURI(data.data.developer_wa) +
            '&text=Hallo%20Saya%20Mau%20info%20properti%20' +
            encodeURI(data.data.developer_name) +
            '.',
        },
        sketch: data.data.sketch,
        facilities: {
          listFacilities: data.data.property_partner_facilities,
          others: data.data.other_facilities,
        },
        location: {
          amenities: data.data.amenities,
          embed: data.data.google_map_url,
        },
        youtube_url: data.data.youtube_url,
      });
    } finally {
      commit('SET_LOADING_PARTNER_DETAIL', false);
    }
  },
  // eslint-disable-next-line no-unused-vars
  clearPropertyPartnerDetail({ commit, state }, params) {
    commit('SET_PROPERTY', null);
  },
};
