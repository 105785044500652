export const state = () => ({
  loading: false,
  areaList: [],
  areaMeta: null,
  areaDetail: null,
  partnerDetail: null,
});

export const mutations = {
  RESTORE_INITIAL_STATE(state) {
    state.loading = false;
    state.list = [];
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_AREA_LIST(state, payload) {
    state.areaList = payload;
  },
  SET_AREA_META(state, payload) {
    state.areaMeta = payload;
  },
  SET_AREA_DETAIL(state, payload) {
    state.areaDetail = payload;
  },
  SET_PARTNER_DETAIL(state, payload) {
    state.partnerDetail = payload;
  },
};

export const actions = {
  async getAreaList({ commit, state }, { listingType, partnerUuid, page }) {
    try {
      commit('SET_LOADING', true);

      let response = await this.$http.get('/api/v2/official_partners/areas', {
        params: {
          listing_type: listingType,
          partner_uuid: partnerUuid,
          page: page ?? 1,
          paginate: 50,
        },
      });
      commit('SET_AREA_LIST', response.data.data);
      commit('SET_AREA_META', response.data.meta);
    } catch (e) {
      console.log('ERROR GET OFFICIAL PARTNER LIST: ', e);
      commit('SET_AREA_LIST', []);
      commit('SET_AREA_META', null);
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }

    return state.areaList;
  },
  async getAreaDetail({ commit, state }, { url }) {
    try {
      commit('SET_LOADING', true);

      let response = await this.$http.get('/api/v2/official_partners/areas/detail', {
        params: {
          url: url,
        },
      });
      commit('SET_AREA_DETAIL', response.data.data);
    } catch (e) {
      console.log('ERROR GET AREA DETAIL: ', e);
      commit('SET_AREA_DETAIL', null);
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }

    return state.areaDetail;
  },
  async getPartnerDetail({ commit, state }, { url }) {
    if (!url || url.includes('.')) {
      throw { message: 'Invalid' };
    }
    try {
      commit('SET_LOADING', true);

      let response = await this.$http.get('/api/v2/official_partners/' + url);
      commit('SET_PARTNER_DETAIL', response.data.data);
    } catch (e) {
      console.log('ERROR GET OFFICIAL PARTNER DETAIL: ', e);
      commit('SET_PARTNER_DETAIL', null);
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }

    return state.partnerDetail;
  },
};
