export const state = () => ({
  clauses: [''],
  useDigitalAgreement: true,
  useOwnAgreement: false,
  isLoading: false,
  newPrice: null,
  bookingRequestHeaderUuid: null,
  customAgreement: {},
});

export const getters = {};

export const mutations = {
  SET_USE_DIGITAL_AGREEMENT(state, payload) {
    state.useDigitalAgreement = payload;
  },
  SET_USE_OWN_AGREEMENT(state, payload) {
    state.useOwnAgreement = payload;
  },
  SET_NEW_PRICE(state, payload) {
    state.newPrice = payload;
  },
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_BOOKING_REQUEST_UUID(state, payload) {
    state.bookingRequestHeaderUuid = payload;
  },
  SET_CUSTOM_AGREEMENT(state, payload) {
    state.customAgreement = payload;
  },

  ADD_CLAUSE(state) {
    if (state.clauses.length < 10) {
      state.clauses.push('');
    }
  },
  REMOVE_CLAUSE(state, index) {
    if (state.clauses.length > 1) {
      state.clauses.splice(index, 1);
    }
  },
  INPUT_CLAUSE(state, payload) {
    state.clauses.splice(payload.index, 1, payload.input);
  },
  RESTORE_INITIAL_STATE(state) {
    state.clauses = [''];
    state.useDigitalAgreement = true;
    state.useOwnAgreement = false;
    state.isLoading = false;
    state.newPrice = null;
    state.bookingRequestHeaderUuid = null;
    state.customAgreement = {};
  },
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async postAcceptBooking({ commit, state }, requestData) {
    try {
      commit('SET_IS_LOADING', true);
      let postData = {
        bookingRequestHeaderUuid: state.bookingRequestHeaderUuid,
        use_digital_agreement: state.useDigitalAgreement,
        final_price: state.newPrice,
        clauses: state.clauses,
      };
      if (state.useOwnAgreement && state.customAgreement && state.customAgreement.uuid) {
        postData.custom_agreement_uuid = state.customAgreement.uuid;
      }
      const { data } = await this.$http.post('api/v2/booking_request/accept', postData);
      console.log('RESPONSE booking req: ', data.data);

      return data.data;
    } finally {
      commit('SET_IS_LOADING', false);
    }

    // let response = await this.$http.post('/api/v2/booking/get-total-rent', requestData);
    //
    // if (response.type === 'success') {
    //     commit('set_total_rent', response.data.total_rent);
    //     commit('set_harga_sewa', response.data.harga_sewa);
    //     commit('set_discount', response.data.discount);
    //     commit('set_discount_price', response.data.discount_price);
    //     commit('set_lama_sewa', response.data.lama_sewa);
    //     let formatDate;
    //     if (requestData.periode_id === 5) {
    //         formatDate = 'dd/MM/yyyy HH:mm:00'
    //     } else {
    //         formatDate = 'dd/MM/yyyy'
    //     }
    //     commit('set_end_date_name', this.$date.format(response.data.end_date, formatDate);
    //     commit('set_start_date_name', this.$date.format(response.data.move_date, formatDate);
    // }
  },

  // eslint-disable-next-line no-unused-vars
  async previewDigitalAgreement({ commit, state }, requestData) {
    try {
      commit('SET_IS_LOADING', true);
      const { data } = await this.$http.post('api/v2/booking/digital-sign-preview', {
        clauses: state.clauses.length == 1 && !state.clauses[0] ? [] : state.clauses,
      });
      return data.data;
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
};
