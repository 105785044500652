// export default function({ store, next }) {
//   if (!store.state.global.loggedIn) {
//     // console.log('MASUK SINI HARUSNYA');
//     // next('/auth/login');
//     // return redirect('/', {
//     //   redirect_back: route.path,
//     // });
//     return next('/auth/login');
//   }
//   return next();
// }
export default async ({ store, redirect }) => {
  if (!store.state.global.loggedIn) {
    redirect('/login');
  }
};
