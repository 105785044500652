export const state = () => ({
  selectedHelp: null,
  helpList: [
    {
      url: 'apa-itu-rentfix',
      title: 'FAQ.rentfixgeneral',
    },
    {
      url: 'listing-di-rentfix',
      title: 'FAQ.addListingHeader',
    },
    {
      url: 'seputar-listing',
      title: 'FAQ.aboutListingHeader',
    },
    {
      url: 'request-to-book',
      title: 'FAQ.requestBook.header',
    },
    {
      url: 'about-booking',
      title: 'FAQ.booking.header',
    },
    {
      url: 'digital-agreement',
      title: 'FAQ.digitalAgreement.header',
    },
    {
      url: 'installment',
      title: 'FAQ.installment.header',
    },
    {
      url: 'health',
      title: 'FAQ.health.header',
    },
    {
      url: 'tutup-akun-rentfix',
      title: 'FAQ.deleteAccount.header',
    },
  ],
});

export const getters = {};

export const mutations = {
  SET_SELECTED_HELP(state, payload) {
    state.selectedHelp = payload;
  },
};

export const actions = {};
