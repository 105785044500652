import xss from 'xss';

export const state = () => ({
  listingData: null,
  applicationData: null,
  messageList: [],
  metaPagination: null,
  userA: null,
  userB: null,
  userInput: '',
  messageThreadUuid: '',
  fetchingMessages: false,
  sendMessageLoading: false,
});

export const mutations = {
  RESTORE_INITIAL_STATE(state) {
    state.listingData = null;
    state.applicationData = null;
    state.messageList = [];
    state.metaPagination = null;
    state.userA = null;
    state.userB = null;
    state.userInput = '';
    state.messageThreadUuid = '';
    state.fetchingMessages = false;
    state.sendMessageLoading = false;
  },
  SET_APPLICATION_DATA(state, payload) {
    state.applicationData = payload;
  },
  SET_FETCHING_MESSAGES(state, payload) {
    state.fetchingMessages = payload;
  },
  SET_SEND_MESSAGE_LOADING(state, payload) {
    state.sendMessageLoading = payload;
  },
  ADD_MESSAGE_LIST(state, payload) {
    const clonedMessageList = JSON.parse(JSON.stringify(state.messageList));
    clonedMessageList.push(payload);
    state.messageList = clonedMessageList;
  },
  SET_MESSAGE_THREAD_UUID(state, payload) {
    state.messageThreadUuid = payload;
  },
  SET_USER_INPUT(state, payload) {
    state.userInput = payload;
  },
  SET_USER_A(state, payload) {
    state.userA = payload;
  },
  SET_USER_B(state, payload) {
    state.userB = payload;
  },
  SET_LISTING_DATA(state, payload) {
    state.listingData = payload;
  },
  SET_MESSAGE_LIST(state, payload) {
    state.messageList = payload;
  },
  SET_META_PAGINATION(state, payload) {
    state.meta = payload;
  },
};

export const actions = {
  async getThreadMessages({ commit, state }) {
    try {
      commit('SET_FETCHING_MESSAGES', true);
      commit('SET_APPLICATION_DATA', null);
      commit('SET_MESSAGE_LIST', []);
      commit('SET_LISTING_DATA', null);
      commit('SET_USER_A', null);
      commit('SET_USER_B', null);

      const { data } = await this.$http.get('/api/v2/myinbox/get_thread_messages', {
        params: {
          messageThreadUuid: state.messageThreadUuid,
        },
      });
      commit('SET_APPLICATION_DATA', data.data.applicationData);
      commit('SET_MESSAGE_LIST', data.data.messages);
      commit('SET_LISTING_DATA', data.data.listing_mini);
      commit('SET_USER_A', data.data.userA);
      commit('SET_USER_B', data.data.userB);
    } finally {
      commit('SET_FETCHING_MESSAGES', false);
    }
  },

  async sendPrivateMessage({ commit, state }) {
    try {
      commit('SET_SEND_MESSAGE_LOADING', true);
      const options = {
        whiteList: {
          br: [],
        },
      };
      let message = xss(state.userInput, options);
      const { data } = await this.$http.post('/api/v2/myinbox/send_private_message', {
        messageThreadUuid: state.messageThreadUuid,
        userInput: message,
      });
      commit('ADD_MESSAGE_LIST', data.data);
      commit('SET_USER_INPUT', '');
    } finally {
      commit('SET_SEND_MESSAGE_LOADING', false);
    }
  },
};
