export const state = () => ({
  totalRent: 0,
  discount: 0,
  discountPrice: 0,
  hargaSewa: 0,
  lamaSewa: '',
  endDateName: '',
  moveDateName: '',
  priceDurationDetail: null,
});

export const mutations = {
  SET_TOTAL_RENT(state, payload) {
    state.totalRent = payload;
  },
  SET_PRICE_DURATION_DETAIL(state, payload) {
    state.priceDurationDetail = payload;
  },
  SET_DISCOUNT(state, payload) {
    state.discount = payload;
  },
  SET_DISCOUNT_PRICE(state, payload) {
    state.discountPrice = payload;
  },
  SET_HARGA_SEWA(state, payload) {
    state.hargaSewa = payload;
  },
  SET_LAMA_SEWA(state, payload) {
    state.lamaSewa = payload;
  },
  SET_END_DATE_NAME(state, payload) {
    state.endDateName = payload;
  },
  SET_MOVE_DATE_NAME(state, payload) {
    state.moveDateName = payload;
  },
};

export const actions = {
  async restoreInitialState({ commit }) {
    commit('SET_PROMO_DETAIL', null);
    commit('SET_PROMO_CODE', null);
    commit('SET_WARNING_MESSAGE', null);
  },

  async getTotalRent({ commit }, requestData) {
    try {
      let response = await this.$http.post('/api/v2/rent_transaction/get_total_rent', requestData);

      if (response.data.type === 'success') {
        commit('SET_TOTAL_RENT', response.data.data.total_rent);
        commit('SET_HARGA_SEWA', response.data.data.harga_sewa);
        commit('SET_DISCOUNT', response.data.data.owner_discount);
        commit('SET_PRICE_DURATION_DETAIL', response.data.data.price_duration_detail);
        commit('SET_DISCOUNT_PRICE', response.data.data.discount_price);
        commit('SET_LAMA_SEWA', response.data.data.lama_sewa);
        let formatDate;
        if (requestData.periode_id == 5) {
          formatDate = 'dd MMM yyyy HH:mm';
        } else {
          formatDate = 'dd MMM yyyy';
        }
        commit(
          'SET_END_DATE_NAME',
          this.$date.format(this.$date.parse(response.data.data.end_date), formatDate),
        );
        commit(
          'SET_MOVE_DATE_NAME',
          this.$date.format(this.$date.parse(response.data.data.move_date), formatDate),
        );
      }
    } catch (e) {
      console.log('ERROR getTotalRent: ', e);
    }
  },
};
