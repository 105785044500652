export const state = () => ({
  useOwnAgreement: false,
  isLoading: false,
  newPrice: null,
  uuid: null,
  customAgreement: {},
});

export const getters = {};

export const mutations = {
  SET_USE_OWN_AGREEMENT(state, payload) {
    state.useOwnAgreement = payload;
  },
  SET_NEW_PRICE(state, payload) {
    state.newPrice = payload;
  },
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_UUID(state, payload) {
    state.uuid = payload;
  },
  SET_CUSTOM_AGREEMENT(state, payload) {
    state.customAgreement = payload;
  },
  RESTORE_INITIAL_STATE(state) {
    state.useOwnAgreement = false;
    state.isLoading = false;
    state.newPrice = null;
    state.uuid = null;
    state.customAgreement = {};
  },
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async postAcceptBooking({ commit, state }, requestData) {
    try {
      commit('SET_IS_LOADING', true);
      let postData = {
        uuid: state.uuid,
        final_price: state.newPrice,
      };
      if (state.useOwnAgreement && state.customAgreement && state.customAgreement.uuid) {
        postData.custom_agreement_uuid = state.customAgreement.uuid;
      }
      const { data } = await this.$http.post('api/v2/rent_transaction/accept', postData);
      console.log('RESPONSE booking req: ', data.data);

      return data.data;
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
};
