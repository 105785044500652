class TimeOfDay {
  constructor(hours, minutes) {
    this.hours = hours;
    this.minutes = minutes;
  }

  toString() {
    console.log('time of day toString1', this.hours);
    const paddedHours = this.hours.toString().padStart(2, '0');
    const paddedMinutes = this.minutes.toString().padStart(2, '0');
    return `${paddedHours}:${paddedMinutes}`;
  }

  static parse(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    return new TimeOfDay(hours, minutes);
  }

  valueOf() {
    return this.hours * 60 + this.minutes;
  }

  isEqual(otherTime) {
    return this.valueOf() === otherTime.valueOf();
  }
}

// Comparison operators overload
TimeOfDay.prototype[Symbol.for('<')] = function (otherTime) {
  return this.valueOf() < otherTime.valueOf();
};

TimeOfDay.prototype[Symbol.for('<=')] = function (otherTime) {
  return this.valueOf() <= otherTime.valueOf();
};

TimeOfDay.prototype[Symbol.for('>')] = function (otherTime) {
  return this.valueOf() > otherTime.valueOf();
};

TimeOfDay.prototype[Symbol.for('>=')] = function (otherTime) {
  return this.valueOf() >= otherTime.valueOf();
};

TimeOfDay.prototype[Symbol.for('==')] = function (otherTime) {
  return this.isEqual(otherTime);
};

module.exports = TimeOfDay;
