export const state = () => ({
  provinces: [],
  province: null,
  cities: [],
  city: null,
  districts: [],
  district: null,
  villages: [],
  village: null,

  openDistrict: false,
  openCity: false,
});

export const getters = {
  formData(state) {
    return {
      province_id: state.province ? state.province.id : state.province,
      city_id: state.city ? state.city.id : state.city,
      district_id: state.district ? state.district.id : state.district,
      village_id: state.village ? state.village.id : state.village,
    };
  },
};

export const mutations = {
  RESTORE_INITIAL_STATE(state) {
    state.province = null;
    state.city = null;
    state.district = null;
    state.village = null;
    state.provinces = [];
    state.cities = [];
    state.districts = [];
    state.villages = [];
    state.openDistrict = false;
    state.openCity = false;
  },
  SET_REGIONAL_DATA(state, payload) {
    state.provinces = payload.provinces;
    state.province = payload.province;
    state.city = payload.city;
    state.cities = payload.cities;
    state.village = payload.village;
    state.villages = payload.villages;
    state.districts = payload.districts;
    state.district = payload.district;
  },
  SET_OPEN_DISTRICT(state, payload) {
    state.openDistrict = payload;
  },
  SET_OPEN_CITY(state, payload) {
    state.openCity = payload;
  },
  SET_PROVINCES(state, payload) {
    state.provinces = payload;
  },
  SET_PROVINCE(state, payload) {
    state.province = payload;
  },
  SET_CITY(state, payload) {
    state.city = payload;
  },
  SET_DISTRICT(state, payload) {
    state.district = payload;
  },
  SET_VILLAGE(state, payload) {
    state.village = payload;
  },
  SET_CITIES(state, payload) {
    state.cities = payload;
  },
  SET_DISTRICTS(state, payload) {
    state.districts = payload;
  },
  SET_VILLAGES(state, payload) {
    state.villages = payload;
  },
};

export const actions = {
  changeProvince({ commit, state }, payload) {
    if (payload) {
      if (payload.hasOwnProperty('id')) {
        commit('SET_PROVINCE', payload);
      } else {
        const pId = payload;
        let selectedProvince = state.provinces.find(function (item) {
          return item.id == pId;
        });
        if (!selectedProvince) {
          selectedProvince = null;
        }
        commit('SET_PROVINCE', selectedProvince);
      }
      commit('SET_OPEN_CITY', true);
    } else {
      commit('SET_PROVINCE', null);
    }
  },
  changeCity({ commit, state }, payload) {
    if (payload) {
      if (payload.hasOwnProperty('id')) {
        commit('SET_CITY', payload);
      } else {
        const cId = payload;
        let selectedCity = state.cities.find(function (item) {
          return item.id == cId;
        });
        if (!selectedCity) {
          selectedCity = null;
        }
        commit('SET_CITY', selectedCity);
      }
      commit('SET_OPEN_DISTRICT', true);
    } else {
      commit('SET_CITY', null);
    }
  },
  changeDistrict({ commit, state }, payload) {
    if (payload) {
      if (payload.hasOwnProperty('id')) {
        commit('SET_DISTRICT', payload);
      } else {
        const dId = payload;
        let selectedDistrict = state.districts.find(function (item) {
          return item.id == dId;
        });
        if (!selectedDistrict) {
          selectedDistrict = null;
        }
        commit('SET_DISTRICT', selectedDistrict);
      }
    } else {
      commit('SET_DISTRICT', null);
    }
  },
  changeVillage({ commit, state }, payload) {
    if (payload) {
      if (payload.hasOwnProperty('id')) {
        commit('SET_VILLAGE', payload);
      } else {
        const vId = payload;
        let selectedVillage = state.villages.find(function (item) {
          return item.id == vId;
        });
        if (!selectedVillage) {
          selectedVillage = null;
        }
        commit('SET_VILLAGE', selectedVillage);
      }
    } else {
      commit('SET_VILLAGE', null);
    }
  },

  async setRegionalData({ commit, dispatch }, requestData) {
    await commit('SET_PROVINCES', requestData.provinces);
    await commit('SET_CITIES', requestData.cities);
    await commit('SET_DISTRICTS', requestData.districts);
    await commit('SET_VILLAGES', requestData.villages);
    dispatch('changeProvince', requestData.province);
    dispatch('changeCity', requestData.city);
    dispatch('changeDistrict', requestData.district);
    dispatch('changeVillage', requestData.village);
  },

  getProvinces({ commit }) {
    this.$http.get('/api/v1/getAllProvince', {}).then((response) => {
      commit('SET_PROVINCES', response.data);
    });
  },

  async getCities({ state, commit, dispatch }) {
    commit('SET_CITIES', []);
    commit('SET_DISTRICTS', []);
    commit('SET_VILLAGES', []);
    dispatch('changeCity', null);
    dispatch('changeDistrict', null);
    dispatch('changeVillage', null);
    await this.$http
      .post('/api/v1/getCityByProvince', {
        province_id: state.province.id,
      })
      .then((result) => {
        commit('SET_CITIES', result.data);
      });
  },

  async getDistricts({ state, commit, dispatch }) {
    commit('SET_DISTRICTS', []);
    commit('SET_VILLAGES', []);
    dispatch('changeDistrict', null);
    dispatch('changeVillage', null);
    await this.$http
      .post('/api/v1/getDistrictByCity', {
        city_id: state.city.id,
      })
      .then((result) => {
        commit('SET_DISTRICTS', result.data);
      });
  },

  async getVillages({ state, commit, dispatch }) {
    commit('SET_VILLAGES', []);
    dispatch('changeVillage', null);
    await this.$http
      .post('/api/v1/getVillageByDistrict', {
        district_id: state.district.id,
      })
      .then((result) => {
        commit('SET_VILLAGES', result.data);
      });
  },
};
