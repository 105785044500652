import axios from 'axios';

const baseUrl =
  process.env.VUE_APP_MODE === 'production' ? 'https://rentfix.com' : 'https://rentfix.com';
import Cookie from 'cookie';
import Cookies from 'js-cookie';

const apiUrl =
  process.env.VUE_APP_MODE === 'production'
    ? process.env.VUE_APP_PROD_API_URL
    : process.env.VUE_APP_LOCAL_API_URL;
const captchaSiteKey =
  process.env.VUE_APP_MODE === 'production'
    ? '6Lc9-jAUAAAAAM3FgiOeEOW99Yb7zTY5QQYV4wsV'
    : '6Lfr7zEUAAAAAB1nER6m4hiPHoFr3vZBUYLJZJWP';
const tinyApiKey = 'vw6rtoplvvfhhm3sakon863c7jprmrpggmsl4l0hstbac4mm';
const logoUrl = 'https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/static/img/logo/logo.png';

export const state = () => ({
  loggedIn: false,
  user: null,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  logoUrl: logoUrl,
  headerLayout: null,
  tinyApiKey: tinyApiKey,
  captchaSiteKey: captchaSiteKey,
  message: null,
  messageType: null,
  galleryHeight: 0,
  bodyClass: 'booking',
  bodyId: 'content',
  modalMask: false,
  closeModal: false,
  listingFormMode: false,
  buttonDisabled: false,
  refreshData: false,
  rows: [],
  token: null,
  isLoading: false,
  locale: {
    id: 'id',
    name: 'Bahasa Indonesia',
  },
  locales: [
    {
      id: 'en',
      name: 'English',
    },
    {
      id: 'id',
      name: 'Bahasa Indonesia',
    },
  ],
  // listingTypes: ['R', 'S'],
  listingTypes: ['R'],
  mainSidebar: false,
  sidebarContent: null,
  showFooter: true,
  openFooter: false,
  remember: false,
  mobileWebView: false,
  // allowedAddSellIds: [41, 45, 291], // kalau isi null, semua bisa add
  allowedAddSellIds: null, // kalau isi null, semua bisa add
  allowed360EmbedIds: [], // kalau isi null, semua bisa add
  allowedListingDiscountIds: [7, 21, 23, 1369, 2557, 4], // kalau isi null, semua bisa add
  allowedDiscountUserUuids: [
    'c28c8794-77b5-11ea-a64d-0a49b1897274',
    'f0f115ca-f7d2-11ea-a8c8-0a49b1897274',
    // '3f2f57ce-f8b3-11ea-a8c8-0a49b1897274',
    'ec823b27-dab9-11ea-a8c8-0a49b1897274',
    'ca1b2426-b6b4-11ea-a8c8-0a49b1897274',
  ],
  allowedDiscountListingPartner: ['urbanspace'],
});
export const getters = {
  allowAddSellListing(state, getters, rootState) {
    if (!rootState.global.user) return false;
    if (rootState.global.allowedAddSellIds === null) return true;
    if (rootState.global.allowedAddSellIds.length === 0) return false;
    return rootState.global.allowedAddSellIds.includes(rootState.global.user.id);
  },
  allow360EmbedListing(state, getters, rootState) {
    if (!rootState.global.user) return false;
    if (rootState.global.allowed360EmbedIds === null) return true;
    if (rootState.global.allowed360EmbedIds.length === 0) return false;
    return rootState.global.allowed360EmbedIds.includes(rootState.global.user.id);
  },
  allowListingDiscount(state, getters, rootState) {
    if (!rootState.global.user) return false;
    if (rootState.global.allowedListingDiscountIds === null) return true;
    if (rootState.global.allowedListingDiscountIds.length === 0) return false;
    return rootState.global.allowedListingDiscountIds.includes(rootState.global.user.id);
  },
  getLocaleName: (state) => {
    const item = state.locales.find(function (item) {
      return item.id === state.locale;
    });
    return item ? item.name : null;
  },
  // eslint-disable-next-line no-unused-vars
  isModalShown: (state, getters) => (modalName) => {
    const selectedModal = state.modals.find(function (modal) {
      return modal.name === modalName;
    });
    return selectedModal.show;
  },
  // eslint-disable-next-line no-unused-vars
  getModalData: (state, getters) => (modalName) => {
    const selectedModal = state.modals.find(function (modal) {
      return modal.name === modalName;
    });
    return selectedModal.data;
  },
  // activeIds(state, getters, rootState) {
  //   const {activeType, itemsPerPage, lists} = state
  //
  //   if (!activeType) {
  //     return []
  //   }
  //
  //   // const page = 1
  //   const page = Number(rootState.route.params.page) || 1
  //   const start = (page - 1) * itemsPerPage
  //   const end = page * itemsPerPage
  //
  //   return lists[activeType].slice(start, end)
  // },
  //
  // activeItems(state, getters) {
  //   return getters.activeIds.map(id => state.items[id]).filter(_ => _)
  // },
  // eslint-disable-next-line no-unused-vars
  getHeaderHeight: (state, getters) => (modalName) => {
    return state.headerLayout ? state.headerLayout.clientHeight : 96;
  },
};
export const mutations = {
  SET_LANG(state, locale) {
    const lang = state.locales.find(function (el) {
      return el.id === locale;
    });
    if (lang !== undefined) {
      state.locale = locale;
      Cookies.set('locale', locale);
    }
  },
  SET_BUTTON_DISABLED(state, payload) {
    state.buttonDisabled = payload;
  },

  SET_TIME_ZONE(state, payload) {
    state.timeZone = payload;
  },
  SET_REFRESH_DATA(state, payload) {
    state.refreshData = payload;
  },
  SET_BODY_CLASS(state, payload) {
    state.bodyClass = payload;
  },
  SET_BODY_ID(state, payload) {
    state.bodyId = payload;
  },
  SET_MODAL_MASK(state, payload) {
    state.modalMask = payload;
  },
  SET_LISTING_FORM(state, payload) {
    state.listingFormMode = payload;
  },
  SHOW_MODAL(state, payload) {
    state.modalMask = true;
    const modalName = payload.modalName;
    const modalData = payload.data;
    state.modals.forEach(function (modalData_) {
      if (modalData_.name === modalName) {
        modalData_.show = true;
        modalData_.data = modalData;
      }
    });
  },
  SET_CLOSE_MODAL(state, payload) {
    state.closeModal = payload;
    state.modalMask = false;
  },
  CLOSE_MODAL(state, modalName) {
    state.closeModal = true;
    state.modalMask = false;
    state.modals.forEach(function (modal) {
      if (modal.name === modalName) {
        modal.show = false;
        modal.data = null;
      }
    });
  },
  SET_ALERT_MESSAGE(state, payload) {
    state.message = payload.message;
    state.messageType = payload.messageType;
  },
  RESET_ALERT(state) {
    state.messageType = null;
    state.message = null;
  },
  SET_GALLERY_HEIGHT(state, payload) {
    state.galleryHeight = payload;
  },

  SET_SHOW_FOOTER_MOBILE(state, payload) {
    state.openFooter = payload;
  },
  SET_SHOW_FOOTER(state, payload) {
    state.showFooter = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },

  SET_REMEMBER(state, remember) {
    state.remember = remember;
  },
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  SET_USER(state, user) {
    state.user = user;
    state.loggedIn = !!user;
  },
  SET_ROWS(state, rows) {
    state.rows = rows;
  },
  SET_LOCALE(state, payload) {
    state.locale = payload;
  },
  SET_MAIN_SIDEBAR(state, payload) {
    state.mainSidebar = payload;
  },
  SET_SIDEBAR_CONTENT(state, payload) {
    state.sidebarContent = payload;
  },
  set_user_input(state, user) {
    state.user = Object.assign({}, state.user, user);
  },
  SET_MOBILE_WEB_VIEW(state, payload) {
    state.mobileWebView = payload;
  },
  SET_HEADER_LAYOUT(state, payload) {
    state.headerLayout = payload;
  },
};

export const actions = {
  // eslint-disable-next-line
  async contactEnquiry({commit}, requestData) {
    return await this.$http.post('/api/v2/contact/enquiry', requestData).then((result) => {
      return result.data;
    });
  },

  // eslint-disable-next-line
  async corporateClient({commit}, requestData) {
    return await this.$http.post('/api/v2/corporate-client/request', requestData).then((result) => {
      return result.data;
    });
  },

  // eslint-disable-next-line
  async officialPartner({commit}, requestData) {
    return await this.$http
      .post('/api/v2/official_partners/register', requestData)
      .then((result) => {
        return result.data;
      });
  },

  // eslint-disable-next-line
  async rentfixOpenApi({commit}, requestData) {
    return await this.$http.post('/api/v2/open_api/register', requestData).then((result) => {
      return result.data;
    });
  },

  async fetchRows({ commit }) {
    const { data } = await this.$http.get('/api/public');
    commit('SET_ROWS', data);
  },

  // Action to login and store user's data in Vuex store
  // async login({ commit }, username) {
  //   const { data } = await this.$http.post('/api/login', {
  //     username: username,
  //   });
  //
  //   if (data.token) {
  //     if (process.client) {
  //       const Cookies = require('js-cookie');
  //       Cookies.set('token', data.token, { path: '/' });
  //     }
  //
  //     commit('SET_USER', data.user);
  //   }
  // },
  // eslint-disable-next-line no-empty-pattern
  async accountActivation({}, token) {
    try {
      const response = await this.$http.get('/api/v2/auth/activation/' + token);

      return response.data;
    } catch (e) {
      throw e;
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async confirmPartnerMembership({}, token) {
    try {
      const newHttpClient = axios.create({
        // Change API url: depends on server side or client side
        baseURL: this.$http.baseURL,
      });
      newHttpClient.defaults.headers = this.$http.defaults.headers;
      console.log('new http client', newHttpClient.defaults);
      const response = await newHttpClient.post(
        `${this.$http.defaults.baseURL}/api/v2/auth/confirm_partner_membership/${token}`,
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  },

  async login({ commit, dispatch }, { fields, endpoint = '/api/v2/auth/login' } = {}) {
    // Send credentials to API
    let data = await this.$http.post(endpoint, fields);

    commit('SET_REMEMBER', fields.remember);

    await dispatch('updateToken', data.data.token);

    await dispatch('fetch');

    return data.data;
  },

  async updateToken({ state, commit }, token) {
    // Update token in store's state
    commit('SET_TOKEN', token);

    // $http.setToken(token, 'Bearer');
    this.$http.defaults.headers.common.Authorization = `Bearer ${token}`;

    if (process.browser) {
      // ...Browser
      if (token) {
        Cookies.set('rentfixApp', token, { expires: state.remember ? 365 : null });
      } else {
        Cookies.remove('rentfixApp');
      }
    }
  },
  // eslint-disable-next-line no-unused-vars
  async registerUser({ commit }, formData) {
    return await this.$http.post('/api/v2/auth/register', formData);
  },
  async checkEmail({ commit }, email) {
    commit('SET_LOADING', true);
    const data = await this.$http.get('/api/v2/auth/check-email?email=' + email);
    commit('SET_LOADING', false);
    return data;
  },

  async fetch({ state, commit, dispatch }, { endpoint = '/api/v2/user' } = {}) {
    await dispatch('fetchToken');
    // Skip if there is no token set
    if (!state.token) {
      return;
    }
    // Try to get user profile
    try {
      const data = await this.$http.get(endpoint);
      commit('SET_USER', data.data.data);
      Cookies.set('user', JSON.parse(JSON.stringify(data.data.data)));
    } catch (e) {
      await dispatch('resetAuth');
    }
  },

  async resetAuth({ dispatch, commit }) {
    commit('SET_USER', null);
    await dispatch('updateToken', null);
  },

  async fetchToken({ dispatch }) {
    let token;

    const cookieStr = process.browser ? document.cookie : null;
    const cookies = Cookie.parse(cookieStr || '') || {};
    token = cookies.rentfixApp;
    if (token) {
      await dispatch('updateToken', token);
    }
  },

  // Simple action to logout current user
  // async logout({ commit }) {
  //   if (process.client) {
  //     require('js-cookie').set('token', null, { path: '/' });
  //   }
  //   commit('SET_USER', {
  //     username: null,
  //     logged: false,
  //   });
  // },
  async logout({ dispatch }, { endpoint = '/api/v1/logout' } = {}) {
    // Server side logout
    try {
      await this.$http.post(endpoint);
    } catch (e) {
      // console.error('Error while logging out', e)
    }
    try {
      await this.$fireAuth.signOut();
    } catch (e) {
      // console.log("Firebase logout error: ", e);
    }
    // Reset store
    await dispatch('resetAuth');
  },
  // eslint-disable-next-line no-unused-vars
  async forgotPassword({ commit }, formData) {
    return await this.$http.post('/api/v1/forgot-password', formData);
  },
  // eslint-disable-next-line no-unused-vars
  async resetPassword({ commit }, fieldData) {
    try {
      let response = await this.$http.post('/api/v2/auth/reset-password', fieldData);

      return response.data;
    } catch (e) {
      throw e;
    }
  },
  // async testApi(context){
  //   const response = await axios.get('https://hacker-news.firebaseio.com/v0/topstories.json?print=pretty');
  //   const getCircularReplacer = () => {
  //     const seen = new WeakSet();
  //     return (key, value) => {
  //       if (typeof value === "object" && value !== null) {
  //         if (seen.has(value)) {
  //           return;
  //         }
  //         seen.add(value);
  //       }
  //       return value;
  //     };
  //   };
  //
  //   return JSON.stringify(response.data, getCircularReplacer());
  //
  // },
  //
  // fetchListData({commit, dispatch, state}, {type}){
  //   commit('SET_ACTIVE_TYPE', {type})
  //   return fetchIdsByType(type)
  //     .then(ids => commit('SET_LIST', {type, ids}))
  //     .then(() => dispatch('ensureActiveItems'))
  // },
  // // FETCH_LIST_DATA: ({commit, dispatch, state}, {type}) => {
  // //
  // // },
  //
  // // ensure all active items are fetched
  // ensureActiveItems({dispatch, getters}){
  //   return dispatch('fetchItems', {
  //     ids: getters.activeIds
  //   })
  // },
  // // ENSURE_ACTIVE_ITEMS: ({dispatch, getters}) => {
  // //   return dispatch('FETCH_ITEMS', {
  // //     ids: getters.activeIds
  // //   })
  // // },
  //
  // fetchItems({commit, state}, {ids}){
  //   // on the client, the store itself serves as a cache.
  //   // only fetch items that we do not already have, or has expired (3 minutes)
  //   const now = Date.now()
  //   ids = ids.filter(id => {
  //     const item = state.items[id]
  //     if (!item) {
  //       return true
  //     }
  //     if (now - item.__lastUpdated > 1000 * 60 * 3) {
  //       return true
  //     }
  //     return false
  //   })
  //   if (ids.length) {
  //     return fetchItems(ids).then(items => commit('SET_ITEMS', {items}))
  //   } else {
  //     return Promise.resolve()
  //   }
  // },
  // // FETCH_ITEMS: ({commit, state}, {ids}) => {
  // //   // on the client, the store itself serves as a cache.
  // //   // only fetch items that we do not already have, or has expired (3 minutes)
  // //   const now = Date.now()
  // //   ids = ids.filter(id => {
  // //     const item = state.items[id]
  // //     if (!item) {
  // //       return true
  // //     }
  // //     if (now - item.__lastUpdated > 1000 * 60 * 3) {
  // //       return true
  // //     }
  // //     return false
  // //   })
  // //   if (ids.length) {
  // //     return fetchItems(ids).then(items => commit('SET_ITEMS', {items}))
  // //   } else {
  // //     return Promise.resolve()
  // //   }
  // // },
  //
  // fetchUser({commit, state}, {id}){
  //   return state.users[id]
  //     ? Promise.resolve(state.users[id])
  //     : fetchUser(id).then(user => commit('SET_USER', {id, user}))
  // },
  // // FETCH_USER: ({commit, state}, {id}) => {
  // //   return state.users[id]
  // //     ? Promise.resolve(state.users[id])
  // //     : fetchUser(id).then(user => commit('SET_USER', {id, user}))
  // // }
};
